import { oauthToken } from '@/api/common'
import { useUserStore } from '@/store/base'
import pinia from '@/store'

const userStore = useUserStore(pinia)

let isRefreshing = false //正在刷新token
let retryRequestList: any[] = [] //token失效期间发起的需要在刷新token后再次发起的请求

// token失效时刷新token的处理，记录当前页面url，刷新token请求，阻塞并记录刷新token期间的请求，再次发起请求
export const useRefreshToken = async (service: any, config: any) => {
  if (!userStore?.userInfo?.tokenInfo?.refresh_token) {
    return
  }
  if (!isRefreshing) {
    isRefreshing = true
    return await refreshTokenFn()
      .then(() => {
        //此处处于当前请求后面的请求在刷新token后首先发出，默认请求之间无继发关系，有继发关系的请求的发出时机默认由各个页面控制
        retryRequestList.map(cb => cb())
        retryRequestList = []
        return service(config) //最后再次发起当前请求，并返回执行结果，由于刷新token期间并未return，发起该请求的页面处理请求pending状态，页面后续then处理未丢失
      })
      .catch(error => {
        return Promise.reject(error) //继续抛出错误，最终传递到页面对应catch
      })
      .finally(() => {
        isRefreshing = false
      })
  } else {
    // 阻塞并记录刷新token期间发起的请求，待刷新token成功后再执行：此处返回Promise，request.ts请求拦截 useRefreshToken 给页面返回相应的Promise，页面处于pending状态。记忆发起请求的位置，对应位置的请求在无感知刷新期间then回调处理也被保留
    return new Promise((resolve, reject) => {
      retryRequestList.push(() => {
        resolve(service(config))
      })
    })
  }
}

// 刷新token请求
const refreshTokenFn = () => {
  const params = new URLSearchParams()
  params.append('grant_type', 'refresh_token')
  params.append('client_id', 'chrg_veh')
  params.append('client_secret', '123456')
  params.append('scope', 'all')
  params.append('refresh_token', userStore?.userInfo?.tokenInfo.refresh_token || '')

  // 此处不捕获错误，继续把错误抛出给request.ts，以处理后续逻辑
  return oauthToken(params).then(res => {
    const rolesList = ['admin'] //暂时给所有用户开放admin权限  TODO
    userStore.setUserInfo({
      username: userStore?.userInfo?.username,
      password: userStore?.userInfo?.password,
      tokenInfo: res.data,
      rolesList,
    })
  })
}

export const clearRetryRequestList = () => {
  retryRequestList = []
}
