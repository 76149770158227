import { defineStore } from 'pinia'
import { BaseObject, Lang } from '@/types'
import { useGetEnDictionary } from '@/hooks'

export interface LangState {
  lang: Lang
  warnNameMap: BaseObject
}

export const useLangStore = defineStore('lang', {
  state: (): LangState => {
    return {
      lang: 'zhCN', //语言默认值，中文
      warnNameMap: {},
    }
  },
  getters: {
    iszhCN: (state): boolean => state.lang === 'zhCN',
  },
  actions: {
    refreshLang: async function () {
      const lang = localStorage.getItem('lang') as Lang //每次刷新页面，lang被重置为zhCN，因此每次beforeEach从sessionStorage重新赋值？ TODO
      if (lang) {
        this.lang = lang
      }
      if (Object.keys(this.warnNameMap).length === 0) {
        const storageStr = sessionStorage.getItem('enDictionary')
        let data: any = {}
        if (storageStr) {
          data = JSON.parse(storageStr)
        } else {
          data = await useGetEnDictionary()
        }
        if (data) {
          this.setDictionary(data)
        }
      }
    },
    setLang(lang: Lang) {
      this.lang = lang
      localStorage.setItem('lang', lang)
    },
    setDictionary(data: any) {
      this.warnNameMap = data.warnNameMap ?? {}

      sessionStorage.setItem(
        'enDictionary',
        JSON.stringify({
          warnNameMap: data.warnNameMap,
        }),
      )
    },
  },
})
