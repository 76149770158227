import { defineStore } from 'pinia'

export interface SceneState {
  messageData: undefined | any[]
  logData: undefined | any[]
}

export const useSceneStore = defineStore('scene', {
  state: (): SceneState => {
    return {
      messageData: undefined,
      logData: undefined,
    }
  },
  actions: {
    updateMessageData(state: any[]) {
      this.messageData = state
    },
    updateLogData(state: any[]) {
      this.logData = state
    },
  },
})
