export default {
  // 全局常量
  globalTips: {
    projectName: '储能服务平台',
    loadErrorMsg: '加载出错',
    loadEmptyMsg: '暂无数据',
    messageBoxTitle: '系统提示',
    queryError: '查询出错',
    codeBtnText: ['获取验证码', '重新获取'],
    batteryStatus: ['离线', '空闲', '充电', '放电', '报警', '故障'],
    yesNo: ['是', '否'],
    optionAll: '全部',
    optionColumn: '操作',
    resetBtn: '重置',
    cancelBtn: '取消',
    queryBtn: '查询',
    saveBtn: '保存',
    confirmBtn: '确定',
    backBtn: '返回',
    uploadBtn: '选择文件',
    saveTip: ['保存成功', '保存出错'],
    commonSearch: {
      inputPlaceholder: '请输入',
      selectPlaceholder: '请选择',
      datePlaceholder: ['开始日期', '结束日期'],
      timePlaceholder: ['开始时间', '结束时间'],
    },
    webLayout: {
      topRightText: '大屏',
      quitText: '退出系统',
    },
    fileUploadCheck: {
      limit: ['当前限制选择 ', ' 个文件，本次选择了', ' 个文件，共选择了 ', ' 个文件'],
      fileType: '上传文件只能是jpeg, jpg, png, gif, pdf, doc, docx, xls, xlsx格式!',
      size: '上传文件大小不能超过 5MB!',
      errorFile: ['以下文件上传错误：', '，', '，点击确定继续保存或者点击取消再次上传'],
    },
    refreshText: '刷新',
    downloadText: '下载',
    exportText: '导出',
    optionColumnName: '操作',
  },
  // 登录
  webLogin: {
    loginType: ['账号密码登录', '手机号登录'],
    accountCheckMsg: ['请输入账号', '请输入密码'],
    loginBtnText: '立即登录',
    registerText: ['还没有账号？', '立即注册'],
    forgetPasswordText: '忘记密码',
    phoneCheckMsg: ['请输入手机号', '请输入正确的手机号', '请输入验证码'],
    codeBtnText: ['获取验证码', '重新获取'],
  },
  // 注册
  registerPage: {
    phone: ['手机号', '请输入手机号', '请输入正确的手机号'],
    code: ['验证码', '请输入验证码'],
    password: ['密码', '请输入密码', '请输入包含数字或字母，长度6-20位的密码'],
    userName: ['登录账户', '请输入登录账户（字母+数字，5-11位）', '请输入包含数字或字母，长度5-11位的登录账户'],
    email: ['邮箱', '请输入邮箱'],
    nickName: ['昵称', '请输入昵称'],
    remark: ['申请原因', '请输入申请原因', '长度不少于6位'],
    successTip: '注册申请已提交，请耐心等待，审核结果将通过邮件通知',
  },
  // 修改密码
  changePassword: {
    phone: ['手机号', '请输入手机号', '请输入正确的手机号'],
    code: ['验证码', '请输入验证码'],
    password: ['密码', '请输入密码', '请输入包含数字或字母，长度6-20位的密码'],
    confirmPassword: ['确认密码', '请输入确认密码', '确认密码与密码不一致', '请输入包含数字或字母，长度6-20位的密码'],
    btnText: '重置密码',
    successTip: '密码重置成功，返回登录',
  },
  // 首页
  home: {
    leftTopTitle: '基础信息',
    leftMiddleTitle: '每日充放电',
    leftBottomTitle: [
      '每日静态压差（最近七天）',
      '电流绝对值小于5A情况下压差的集合（最大值减最小值）',
      '每日最高温度（最近七天）',
      '最大温度值的集合',
    ],
    rightTopTitle: ['单体电芯电压', '3D图：最新时刻点所有电芯电压值', '折线图：最新半小时内所有电芯电压值'],
    rightBottomTitle: ['温度', '3D图：最新时刻点所有温度传感器的温度值', '折线图：最新半小时内所有温度传感器的温度值'],
    changeText: ['切换折线图', '切换3D图'],
    inputTip: '点击选择车辆',
    emptyTip: '选择VIN号获取数据',
    baseInfoList: ['VIN', '场站', '充电总量', '放电总量', '最早上线时间', '最近在线时间', '报警状态', '数据暂未上报'],
    pieChart: ['剩余电量', '电流', '温度'],
    batteryInfoList: ['电池总数', '平均电压', '最高电压', '最低电压'],
    barChart: ['电压', '电芯', '温度'],
    lineChart: ['电芯电压/V', '温度/℃'],
    kChart: ['压差/V', '温度/℃'],
    kTitle: [
      ['当前第一笔静态deltav：', '当天最后一笔静态deltav：', '当天最小静态deltav：', '当前最大静态deltav：'],
      ['当天最大温度的第一笔：', '当天最大温度的最后一笔：', '当天最大温度的最小值：', '当天最大温度的最大值：'],
    ],
  },

  // 大屏
  energyStoragePowerStation: {
    pageTitle: '柳州',
    topTitle: '储能电站安全管理平台',
    leftTitle: ['近一月报警状态', '报警分析'],
    leftTopColumn: ['类型', '故障', '状态', '最新日期'],
    leftBottomColumn: ['位置', '名称', '等级', '状态'],
    centerTitle: ['监控指标总览', '最后数据时间：', '储能电池舱'],
    indicatorList: ['安全运行天数(天)', '总充电量(MWh)', '总放电量(MWh)'],

    centerBottomLeft: ['基础数据', '电站额定功率', '电站额定电量', '最大可充功率', '最大可放功率'],
    centerBottomRight: ['实时监控', '充电 / 放电总功率', '日放电量', '日充电量'],
    rightTitle: ['报警分析', '报警趋势分析', '处理状态分布'],
    rightTop: ['报警日期', '报警类型'],
    alarmDateOptions: '近一个月',
    alarmTypeOptions: ['电芯', '系统'],
    legendList: ['运维报警', '性能报警', '安全报警'],
    alarmTypeList: ['云端报警', 'BMS报警'],
    rightCenterChart: '频次',
    rightBottomChart: ['数量', '新增', '处理中', '已解决'],
  },
}
