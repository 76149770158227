import en from './en'
import zhCN from './zh-cn'
import { createI18n } from 'vue-i18n'

const langStorage = localStorage.getItem('locale')
const locale = langStorage ? (langStorage === 'zh-cn' ? 'zhCN' : 'en') : 'zhCN'
const i18n = createI18n({
  legacy: false,
  locale,
  globalInjection: true, // 表明使用全局t函数
  messages: {
    zhCN,
    en,
  },
})

export default i18n
