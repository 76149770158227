<template>
  <template v-if="!item.meta?.hidden">
    <!-- 只有一个非隐藏的子路由 + 非恒显子元素；没有子路由。渲染el-menu-item -->
    <template v-if="hasOneShowingChild(item.children, item) && item.meta?.alwaysShow !== true">
      <!-- index 与 路由完全一致才会添加 is-active 样式名，父级path redirect属性值 === resolvePath(onlyOneChild.path) === 路由 -->
      <el-menu-item
        :index="resolvePath(onlyOneChild.path)"
        v-if="item.meta"
        @click="skipRoute(resolvePath(onlyOneChild.path))"
      >
        <el-icon :size="16" v-if="mode === 'vertical' && item.meta?.icon">
          <svg-icon
            v-if="mode === 'vertical'"
            :iconName="item.meta?.icon"
            :color="getIconColor(resolvePath(item.path))"
          ></svg-icon>
        </el-icon>
        <template #title>
          <span :style="{ color: getIconColor(resolvePath(item.path), true) }">{{ item.meta.title }}</span>
        </template>
      </el-menu-item>
    </template>

    <!-- 只有一个非隐藏的子路由 + 恒显子元素；包含多个非隐藏的子路由。渲染el-sub-menu -->
    <el-sub-menu v-else :index="resolvePath(item.path)" popper-class="custom-popper">
      <template #title>
        <el-icon :size="16" v-if="mode === 'vertical' && item.meta?.icon">
          <svg-icon
            v-if="mode === 'vertical'"
            :iconName="item.meta?.icon"
            :color="getIconColor(resolvePath(item.path))"
          ></svg-icon>
        </el-icon>
        <span :style="{ color: getIconColor(resolvePath(item.path)) }">{{ item.meta?.title }}</span>
      </template>
      <!-- 每个 child 可能包含多个子路由，使用<nav-menu-item>继续向下嵌套  -->
      <nav-menu-item
        v-for="child in item.children"
        :key="child.path"
        :item="child"
        :base-path="resolvePath(child.path)"
        :mode="mode"
        :collapse="props.collapse"
      />
    </el-sub-menu>
  </template>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue'
import { useRouter } from 'vue-router'
import { RouteRecordRaw } from 'vue-router'
import path from 'path-browserify'
import { isExternal } from '@/utils/index'

const props = withDefaults(
  defineProps<{
    item: RouteRecordRaw
    basePath: string
    mode: string //侧边导航显示导航图标
    collapse: boolean
  }>(),
  {
    mode: 'vertical',
    collapse: false,
  },
)

let router = useRouter()
const skipRoute = (path: string) => {
  router.push(path)
}

const onlyOneChild = ref() // 临时变量，唯一子路由

/**
 * 判断当前路由是否只有一个子路由
 *
 * 1：如果只有一个子路由： 返回 true
 * 2：如果无子路由 ：返回 true
 *
 * @param children 子路由数组
 * @param parent 当前路由
 */
function hasOneShowingChild(children = [], parent: any) {
  // 需要显示的子路由数组
  const showingChildren = children.filter((item: any) => {
    if (item.meta?.hidden) {
      return false // 过滤不显示的子路由
    } else {
      onlyOneChild.value = item // 唯一子路由赋值（多个子路由情况 onlyOneChild 变量是用不上的）
      return true
    }
  })
  // console.log('----hasOneShowingChild', showingChildren.length, parent)

  // 1：如果只有一个子路由, 返回 true
  if (showingChildren.length === 1) {
    return true
  }

  // 2：如果无子路由, 复制当前路由信息作为其子路由，满足只拥有一个子路由的条件，所以返回 true
  if (showingChildren.length === 0) {
    onlyOneChild.value = { ...parent, path: '', noShowingChildren: true }
    // console.log('----onlyOneChild.value', children, onlyOneChild.value, parent)
    return true
  }
  return false
}

/**
 * 解析路径
 *
 * @param routePath 路由路径
 */
function resolvePath(routePath: string) {
  if (isExternal(routePath)) {
    return routePath
  }
  if (isExternal(props.basePath)) {
    return props.basePath
  }
  // 完整路径 = 父级路径(/level/level_3) + 路由路径
  const fullPath = path.resolve(props.basePath, routePath) // 相对路径 → 绝对路径
  // console.log('resolvePath', props.basePath, ' --1 ', routePath, ' --2 ', fullPath)
  return fullPath
}

let defaultActive = ref() //当前路由
let defaultActiveParentPath = ref() //当前路由的父级路由
watch(
  () => router.currentRoute.value,
  (newValue, oldValue) => {
    defaultActive.value = newValue.path
    let parentPath = newValue.path.split('/')?.filter(item => !!item)?.[0]
    parentPath = parentPath ? '/' + parentPath : '-'
    defaultActiveParentPath.value = parentPath
    // console.log('----defaultActive.value', defaultActive.value, parentPath)
  },
  {
    immediate: true,
  },
)

// 导航图标是否高亮
const getIconColor = (path: string, isElMenuTitle?: boolean) => {
  // 垂直el-menu，折叠el-menu时，el-menu-item 组件封装默认使用了 el-tooltip展示 #title插槽，提示框背景色默认是灰色
  // 折叠时，嵌套的nav-menu弹窗不可使用白色，此处暂时写死，TODO
  let oneChildNameList = ['Home', 'AlarmList', 'DataAnalysis', 'WorkOrder', 'Scene']
  if (
    isElMenuTitle &&
    !!oneChildNameList.find(item => item === props.item.name) &&
    props.mode === 'vertical' &&
    props.collapse === true
  ) {
    return '#fff'
  }

  // 选中二级页面，对应一级导航高亮
  // console.log('getIconColor', path, isElMenuTitle)
  if (defaultActive.value === path || defaultActiveParentPath.value === path) {
    return '#eb853b'
  }
  return '#333'
}

// onBeforeMount(() => {
//   defaultActive.value = router.currentRoute.value.path
// })
</script>

<style scoped lang="less">
:deep .el-menu {
  .el-submenu {
    background-color: #001529 !important;
    // 二级菜单 ( 默认背景 )
    .el-menu-item {
      padding-left: 50px !important;
      background-color: #0c2135 !important;
    }
  }
  .el-submenu__title {
    background-color: #001529 !important;
  }
  // hover 高亮
  .el-menu-item:hover {
    color: #eb853b !important;
    background-color: #f7eee5 !important;
  }
  .el-menu-item.is-active {
    color: #eb853b !important;
    background-color: #fff !important;
  }
  .el-sub-menu.is-active {
    .el-sub-menu__title {
      span {
        color: #eb853b !important;
      }
    }
  }
  .el-menu-vertical:not(.el-menu--collapse) {
    width: 100%;
    height: calc(100% - 48px);
  }
}
// 覆盖 el-menu--horizontal 默认高度
.custom-popper {
  height: auto !important;
}
</style>
