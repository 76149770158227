import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, isRef as _isRef, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["src"]

import { ref, computed } from 'vue'
import { ElMessage } from 'element-plus'
import { Plus } from '@element-plus/icons-vue'
import type { UploadProps, UploadUserFile, UploadFile, UploadInstance } from 'element-plus'


export default /*@__PURE__*/_defineComponent({
  __name: 'ImgUpload',
  props: {
    fileList: { default: [] as UploadUserFile[] },
    limit: { default: 2 },
    pictureCardSize: { default: 148 },
    disabled: { type: Boolean, default: false }
  },
  emits: ['update:fileList', 'httpRequest', 'remove'],
  setup(__props: any, { emit: __emit }) {

const props = __props
const emit = __emit

let fileList = computed({
  get: () => props.fileList,
  set: val => {
    console.log('fileList--set', val)
    emit('update:fileList', val)
  },
})
let computedStyle = computed(() => {
  return '--el-upload-list-picture-card-size:' + props.pictureCardSize + 'px'
})

// 文件数量超出
const onExceed: UploadProps['onExceed'] = (files, uploadFiles) => {
  console.log('onExceed', files, uploadFiles, fileList.value)
  ElMessage.error(`只能选择 1 个文件，当前共选择了 ${files.length + uploadFiles.length} 个`)
}

// 移除，不使用 <template #file="{ file }"> 自动更新 fileList
const onRemove: UploadProps['onRemove'] = (uploadFile, uploadFiles?) => {
  console.log('onRemove', uploadFile, uploadFiles, fileList.value)
}

// 文件状态改变时的钩子，添加文件、上传成功和上传失败时都会被调用
const onChange: UploadProps['onChange'] = (file, uploadFiles) => {
  console.log('onChange', file, uploadFiles, fileList.value)
}

// 上传文件之前的钩子，返回false阻止上传。
let fileSuffix = ref('')
const beforeUpload: UploadProps['beforeUpload'] = rawFile => {
  console.log('beforeUpload', rawFile)
  var suffixName = rawFile.name.split('.').pop()!
  fileSuffix.value = suffixName
  const isCorrectSuffix = ['jpg', 'jpeg', 'gif', 'png'].indexOf(suffixName) != -1
  const isLt10M = rawFile.size / 1024 / 1024 < 10
  if (!isCorrectSuffix) {
    ElMessage.error('上传文件只能是图片格式!')
  }
  if (!isLt10M) {
    ElMessage.error('上传文件大小不能超过 10MB!')
  }
  return isCorrectSuffix && isLt10M
}

// 文件上传成功时的钩子。不会触发？TODO
const onSuccess: UploadProps['onSuccess'] = (response, uploadFiles) => {
  console.log('onSuccess', response, uploadFiles, fileList.value)
}

// 预览
let dialogVisible = ref(false)
let dialogImageUrl = ref('')
const handlePictureCardPreview: UploadProps['onPreview'] = uploadFile => {
  dialogImageUrl.value = uploadFile.url!
  dialogVisible.value = true
}

const handleDownload = (file: UploadFile) => {
  console.log(file)
}

// http-request 自定义上传请求
const confirmUpload = () => {
  console.log('confirmUpload', fileList.value)
  emit('httpRequest', fileList)
}

let imgUploadRef = ref<UploadInstance>()

return (_ctx: any,_cache: any) => {
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_upload = _resolveComponent("el-upload")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_el_upload, {
      ref_key: "imgUploadRef",
      ref: imgUploadRef,
      "file-list": _unref(fileList),
      "onUpdate:fileList": _cache[0] || (_cache[0] = ($event: any) => (_isRef(fileList) ? (fileList).value = $event : fileList = $event)),
      limit: _ctx.limit,
      multiple: _ctx.limit > 1,
      accept: "image/*",
      "list-type": "picture-card",
      "auto-upload": true,
      "http-request": confirmUpload,
      "on-exceed": onExceed,
      "before-upload": beforeUpload,
      "on-change": onChange,
      "on-success": onSuccess,
      "on-remove": onRemove,
      "on-preview": handlePictureCardPreview,
      action: "",
      style: _normalizeStyle(_unref(computedStyle)),
      disabled: _ctx.disabled
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_icon, null, {
          default: _withCtx(() => [
            _createVNode(_unref(Plus))
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["file-list", "limit", "multiple", "style", "disabled"]),
    _createVNode(_component_el_dialog, {
      modelValue: _unref(dialogVisible),
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_isRef(dialogVisible) ? (dialogVisible).value = $event : dialogVisible = $event))
    }, {
      default: _withCtx(() => [
        _createElementVNode("img", {
          "w-full": "",
          src: _unref(dialogImageUrl),
          alt: "Preview Image"
        }, null, 8, _hoisted_1)
      ]),
      _: 1
    }, 8, ["modelValue"])
  ], 64))
}
}

})