export default {
  globalTips: {
    projectName: 'EnergyStorage Service Platform',
    loadErrorMsg: 'Loading Error',
    loadEmptyMsg: 'No Data',
    messageBoxTitle: 'System Prompt',
    queryError: 'Something went wrong here',
    codeBtnText: ['Get Code', 'Resend'],
    batteryStatus: ['Offline', 'Idle', 'Charging', 'Discharging', 'Warning', 'Malfunction'],
    yesNo: ['Yes', 'No'],
    optionAll: 'All',
    optionColumn: 'Operation',
    resetBtn: 'Reset',
    cancelBtn: 'Cancel',
    queryBtn: 'Query',
    saveBtn: 'Save',
    confirmBtn: 'Confirm',
    backBtn: 'Back',
    uploadBtn: 'Choose files',
    saveTip: ['Save Successful', 'Save Error'],
    commonSearch: {
      inputPlaceholder: 'Please enter ',
      selectPlaceholder: 'Please select ',
      datePlaceholder: ['Start Date', 'End Date'],
      timePlaceholder: ['Start Time', 'End Time'],
    },
    webLayout: {
      topRightText: 'Overview', //Central Monitoring
      quitText: 'Log out',
    },
    fileUploadCheck: {
      // limit: ['The current limit is ', ' files, this time ', ' files are selected, a total of ', ' files are selected'],
      // fileType: 'Upload files can only be jpeg, jpg, png, gif, pdf, doc, docx, xls, xlsx format!',
      // size: 'Upload file size cannot exceed 5MB!',
      limit: ['Select up to ', ' files, ', ' files selected, ', ' files in total'],
      fileType: 'Uploadable file formats: jpeg, jpg, png, gif, pdf, doc, docx, xls, xlsx',
      size: 'Maximum file size: 5MB',
      errorFile: [
        'The following files failed to upload: ',
        ',',
        '. Click Save to continue saving or click Cancel to upload again.',
      ],
    },
    refreshText: 'Refresh',
    downloadText: 'Download',
    exportText: 'Export',
    optionColumnName: 'Options',
  },
  webLogin: {
    loginType: ['Account and Password', 'Phone Number'],
    accountCheckMsg: ['Please enter your account', 'Please enter your password'],
    loginBtnText: 'Log in',
    registerText: ["Don't have an account? ", 'Sign up'],
    forgetPasswordText: 'Forgot Password',
    phoneCheckMsg: [
      'Please enter your phone number',
      'Please enter a valid phone number',
      'Please enter the verification code',
    ],
    codeBtnText: ['Get Verification Code', 'Resend'],
  },
  registerPage: {
    phone: ['Phone Number', 'Please enter your phone number', 'Please enter a valid phone number'],
    code: ['Verification Code', 'Please enter the verification code'],
    password: [
      'Password',
      'Please enter a password',
      'Please enter a password with at least 6-20 characters including numbers or letters',
    ],
    userName: [
      'Login Account',
      'Please enter your login account (letters + numbers, 5-11 characters)',
      'Please enter a login account with at least 5-11 characters including numbers or letters',
    ],
    email: ['Email', 'Please enter your email'],
    nickName: ['Nickname', 'Please enter your nickname'],
    remark: ['Application Reason', 'Please enter the application reason, at least 6 characters'],
    successTip:
      'Registration application has been submitted. Please be patient, the audit result will be notified by email.',
  },
  changePassword: {
    phone: ['Phone Number', 'Please enter your phone number', 'Please enter a valid phone number'],
    code: ['Verification Code', 'Please enter the verification code'],
    password: [
      'Password',
      'Please enter a password',
      'Please enter a password with at least 6-20 characters including numbers or letters',
    ],
    confirmPassword: [
      'Confirm Password',
      'Please enter the confirmation password',
      'Password and confirmation password do not match',
      'Please enter a password with at least 6-20 characters including numbers or letters',
    ],
    btnText: 'Reset Password',
    successTip: 'Password reset successful, return to login',
  },
  home: {
    leftTopTitle: 'Basic Information',
    leftMiddleTitle: 'Daily Charge and Discharge',
    leftBottomTitle: [
      'Daily Static Voltage Difference (last seven days)',
      'Collection of voltage differences under current less than 5A (max minus min)',
      'Daily Highest Temperature (last seven days)',
      'Collection of maximum temperature values',
    ],
    rightTopTitle: [
      'Cell Voltage',
      '3D Chart: Voltage values of all cells at the latest time point',
      'Line Chart: Voltage values of all cells within the latest half hour',
    ],
    rightBottomTitle: [
      'Temperature',
      '3D Chart: Temperature values of all sensors at the latest time point',
      'Line Chart: Temperature values of all sensors within the latest half hour',
    ],
    changeText: ['Switch to Line Chart', 'Switch to 3D Chart'],
    inputTip: 'Click to select vehicle',
    emptyTip: 'Select VIN to get data',
    baseInfoList: [
      'VIN',
      'Station',
      'Total Charging',
      'Total Discharging',
      'First Online Time',
      'Last Online Time',
      'Alarm Status',
      'Data Not Yet Reported',
    ],
    pieChart: ['Remaining Power', 'Current', 'Temperature'],
    batteryInfoList: ['Total Number of Batteries', 'Average Voltage', 'Highest Voltage', 'Lowest Voltage'],
    barChart: ['Voltage', 'Cell', 'Temperature'],
    lineChart: ['Cell Voltage / V', 'Temperature / ℃'],
    kChart: ['Voltage Difference / V', 'Temperature / ℃'],
    kTitle: [
      [
        'Current first static deltav:',
        'Last static deltav of the day:',
        'Minimum static deltav of the day:',
        'Maximum static deltav currently:',
      ],
      [
        'First record of the highest temperature of the day:',
        'Last record of the highest temperature of the day:',
        'Minimum value of the highest temperature of the day:',
        'Maximum value of the highest temperature of the day:',
      ],
    ],
  },

  energyStoragePowerStation: {
    pageTitle: 'LiuZhou',
    topTitle: 'ESS Management',
    leftTitle: ["Recent Month's Warning Status", 'Warning Analysis'],
    leftTopColumn: ['Type', 'Malfunction', 'Status', 'Latest Date'],
    leftBottomColumn: ['Location', 'Name', 'Level', 'Status'],
    centerTitle: ['Monitoring Metrics Overview', 'Last Data Time: ', 'ESS Container'],
    indicatorList: ['Safe Operating Days (Days)', 'Total Charge (MWh)', 'Total Discharge (MWh)'],

    centerBottomLeft: ['Basic Data', 'Power', 'Capacity', 'Max Charge Power', 'Max Discharge Power'],
    centerBottomRight: [
      'Real-time Monitoring',
      'Total Charged / Discharged Power',
      'Daily Discharging Capacity',
      'Daily Charging Capacity',
    ],
    rightTitle: ['Warning Analysis', 'Trending Analysis', 'Distribution Of Resolution Status'],
    rightTop: ['Warning Date', 'Warning Type'],
    alarmDateOptions: 'Past Month',
    alarmTypeOptions: ['Cell', 'System'],
    legendList: ['Operation Warning', 'Performance Warning', 'Safety Warning'],
    alarmTypeList: ['Cloud Warning', 'BMS Warning'],
    rightCenterChart: 'frequency',
    rightBottomChart: ['Quantity', 'New', 'In Progress', 'Resolved'],
  },
}
