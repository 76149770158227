<!-- 组件设计：CommonSearch查询处理handleQuery放在父组件页面；
  CommonPagination数据pageData:{pageNum,pageSize,totalCount}在页面内使用hooks统一生成，可供CommonSearch、CommonPagination修改使用；
  CommonTable只做展示，不操作其他组件数据 -->
<template>
  <div class="common-table-component">
    <!-- :max-height 不能随ref值修改而重新渲染，使用:style？？？ -->
    <el-table
      :data="tableData.tableList"
      :stripe="props.stripe"
      :max-height="maxHeight"
      :header-cell-style="props.headerCellStyle ? { background: '#EBF3FE', color: '#333' } : undefined"
      :show-summary="!!props.summaryMethod"
      :summary-method="!!props.summaryMethod ? props.summaryMethod : undefined"
      :highlight-current-row="props.hightlightCurrentRow"
      @selection-change="handleSelectionChange"
      @current-change="handleCurrentChange"
      @row-dblclick="handleDblClick"
      @row-click="handleRowClick"
      :style="{ 'max-height': maxHeight + 'px' }"
      v-loading="props.loading"
      :empty-text="props.emptyText"
      :border="props.border"
    >
      <el-table-column v-if="!!props.isShowIndex" type="index" width="55"></el-table-column>
      <el-table-column v-if="!!props.isShowSelection" type="selection" width="55"></el-table-column>
      <el-table-column
        v-for="(item, index) in columnList"
        :key="index"
        :prop="item.prop"
        :label="item.label"
        :min-width="item.minWidth || 110"
        :width="item.width || undefined"
        :show-overflow-tooltip="item.showOverflowTooltip || true"
      >
        <template v-if="item.headerSlotName" #header="scope">
          {{ item.label }}<slot v-if="item.headerSlotName" :name="item.headerSlotName" :row="scope.row"></slot>
        </template>

        <!-- 父组件内多个<el-table-column>的命名插槽占位借助CommonTable组件各个<el-table-column>的默认插槽实现。
        通过 <template  #default="scope"> 把子组件作用域传递给 <slot :name='' :row=scope.row> 命名插槽再把变量传回父组件对应名称的<template #name="scope">，
          最终把子组件作用域变量传递给父组件  -->
        <template v-if="item.slotName" #default="scope">
          <slot v-if="item.slotName" :name="item.slotName" :row="scope.row"></slot>
        </template>
      </el-table-column>
      <el-table-column
        v-if="props.isShowOption"
        :min-width="110"
        :label="props.optionColumnName || '操作'"
        fixed="right"
      >
        <template #default="scope">
          <slot name="optionColumn" :row="scope.row"></slot>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script lang="ts" setup>
import { onMounted, reactive, ref, toRef, toRefs, isRef } from 'vue'
import { useTableMaxHeight } from '@/hooks/index'
import { debounce } from 'lodash-es'
// import { TableData, columnItem } from '@/types/CommonTable'

interface TableData {
  tableList: any[]
  multipleSelection?: any[]
}
interface ColumnItem {
  prop: string
  label: string
  width?: number
  minWidth?: number
  showOverflowTooltip?: boolean
  slotName?: string
  headerSlotName?: string // 表格项 表格头 插槽名，slotName+'Header'
}

let props = withDefaults(
  defineProps<{
    tableData: TableData
    columnList: ColumnItem[]
    isShowIndex?: boolean //索引列
    isShowSelection?: boolean //多选框列
    isShowOption?: boolean //操作列
    summaryMethod?: (data: { columns: any[]; data: any[] }) => string[]
    loading?: boolean
    emptyText?: string
    optionColumnName?: string
    hightlightCurrentRow?: boolean //单选
    border?: boolean
    headerCellStyle?: boolean //标题行默认背景色
    stripe?: boolean
    maxHeight?: number
  }>(),
  {
    tableData: () => ({
      tableList: [],
      multipleSelection: [],
    }),
    isShowIndex: false,
    isShowSelection: false,
    isShowOption: false,
    loading: false,
    hightlightCurrentRow: false,
    border: false,
    headerCellStyle: true,
    stripe: true,
  },
)

const emit = defineEmits(['dblClick', 'currentChange', 'selectionChange', 'rowClick'])

let { tableData, columnList } = toRefs(props)

// 多选
const handleSelectionChange = (val: any[]) => {
  tableData.value.multipleSelection = val
  emit('selectionChange')
}
// 单选
const handleCurrentChange = (currentRow: any, oldCurrentRow: any) => {
  tableData.value.multipleSelection = [currentRow]
  emit('currentChange')
}
// 单击行
const handleRowClick = (row, column, event) => {
  if (props.hightlightCurrentRow) {
    tableData.value.multipleSelection = [row]
  }
  emit('rowClick', row)
}
// 双击行
const handleDblClick = (row: any) => {
  emit('dblClick', row)
}

let maxHeight = ref(props.maxHeight ?? 600)

onMounted(() => {
  if (!props.maxHeight) {
    maxHeight.value = useTableMaxHeight()
    window.addEventListener(
      'resize',
      debounce(() => {
        maxHeight.value = useTableMaxHeight()
      }, 150),
    )
  }
})
</script>

<style scoped lang="less"></style>
