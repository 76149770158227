<template>
  <el-upload
    ref="imgUploadRef"
    v-model:file-list="fileList"
    :limit="limit"
    :multiple="limit > 1"
    accept="image/*"
    list-type="picture-card"
    :auto-upload="true"
    :http-request="confirmUpload"
    :on-exceed="onExceed"
    :before-upload="beforeUpload"
    :on-change="onChange"
    :on-success="onSuccess"
    :on-remove="onRemove"
    :on-preview="handlePictureCardPreview"
    action=""
    :style="computedStyle"
    :disabled="disabled"
  >
    <el-icon><Plus /></el-icon>
    <!-- <template #file="{ file }">
      <div>
        <img class="el-upload-list__item-thumbnail" :src="file.url" alt="" />
        <span class="el-upload-list__item-actions">
          <span class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)">
            <el-icon><zoom-in /></el-icon>
          </span>
          <span v-if="!disabled" class="el-upload-list__item-delete" @click="handleDownload(file)">
            <el-icon><Download /></el-icon>
          </span>
          <span v-if="!disabled" class="el-upload-list__item-delete" @click="onRemove(file)">
            <el-icon><Delete /></el-icon>
          </span>
        </span>
      </div>
    </template> -->
  </el-upload>

  <el-dialog v-model="dialogVisible">
    <img w-full :src="dialogImageUrl" alt="Preview Image" />
  </el-dialog>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue'
import { ElMessage } from 'element-plus'
import { Plus } from '@element-plus/icons-vue'
import type { UploadProps, UploadUserFile, UploadFile, UploadInstance } from 'element-plus'

const props = withDefaults(
  defineProps<{
    fileList?: UploadUserFile[]
    limit?: number
    pictureCardSize?: number
    disabled?: boolean
  }>(),
  {
    fileList: [] as UploadUserFile[],
    limit: 2,
    pictureCardSize: 148,
    disabled: false,
  },
)
const emit = defineEmits(['update:fileList', 'httpRequest', 'remove'])

let fileList = computed({
  get: () => props.fileList,
  set: val => {
    console.log('fileList--set', val)
    emit('update:fileList', val)
  },
})
let computedStyle = computed(() => {
  return '--el-upload-list-picture-card-size:' + props.pictureCardSize + 'px'
})

// 文件数量超出
const onExceed: UploadProps['onExceed'] = (files, uploadFiles) => {
  console.log('onExceed', files, uploadFiles, fileList.value)
  ElMessage.error(`只能选择 1 个文件，当前共选择了 ${files.length + uploadFiles.length} 个`)
}

// 移除，不使用 <template #file="{ file }"> 自动更新 fileList
const onRemove: UploadProps['onRemove'] = (uploadFile, uploadFiles?) => {
  console.log('onRemove', uploadFile, uploadFiles, fileList.value)
}

// 文件状态改变时的钩子，添加文件、上传成功和上传失败时都会被调用
const onChange: UploadProps['onChange'] = (file, uploadFiles) => {
  console.log('onChange', file, uploadFiles, fileList.value)
}

// 上传文件之前的钩子，返回false阻止上传。
let fileSuffix = ref('')
const beforeUpload: UploadProps['beforeUpload'] = rawFile => {
  console.log('beforeUpload', rawFile)
  var suffixName = rawFile.name.split('.').pop()!
  fileSuffix.value = suffixName
  const isCorrectSuffix = ['jpg', 'jpeg', 'gif', 'png'].indexOf(suffixName) != -1
  const isLt10M = rawFile.size / 1024 / 1024 < 10
  if (!isCorrectSuffix) {
    ElMessage.error('上传文件只能是图片格式!')
  }
  if (!isLt10M) {
    ElMessage.error('上传文件大小不能超过 10MB!')
  }
  return isCorrectSuffix && isLt10M
}

// 文件上传成功时的钩子。不会触发？TODO
const onSuccess: UploadProps['onSuccess'] = (response, uploadFiles) => {
  console.log('onSuccess', response, uploadFiles, fileList.value)
}

// 预览
let dialogVisible = ref(false)
let dialogImageUrl = ref('')
const handlePictureCardPreview: UploadProps['onPreview'] = uploadFile => {
  dialogImageUrl.value = uploadFile.url!
  dialogVisible.value = true
}

const handleDownload = (file: UploadFile) => {
  console.log(file)
}

// http-request 自定义上传请求
const confirmUpload = () => {
  console.log('confirmUpload', fileList.value)
  emit('httpRequest', fileList)
}

let imgUploadRef = ref<UploadInstance>()
</script>

<style scoped lang="less">
:deep .el-upload-list__item {
  width: 80px !important;
  height: 80px !important;
}
:deep .el-upload--picture-card {
  width: 80px !important;
  height: 80px !important;
}
</style>
