import request, { bdService, noTokenService } from '@/utils/request'

// 登录
export function oauthToken(data: any) {
  // return request({
  //   url: 'api/uaa/oauth/token',
  //   method: 'post',
  //   data,
  //   // headers: { 'content-type': 'application/x-www-form-urlencoded' },
  // })
  return noTokenService({
    url: 'api/uaa/oauth/token',
    method: 'post',
    data,
  })
}

// 查询所有值集编码
export function dictionaryAll(data: any) {
  return request({
    url: 'api/dc/dictionary/_all',
    method: 'get',
  })
}

// 根据值集编码查询字典
export function dictionaryID(data: any) {
  return request({
    url: `api/dc/dictionary/${data.id}/items`,
    method: 'get',
  })
}

// 字典-中文
export function dictionaryIDForZHCN(data: any) {
  return request({
    url: `api/dc/dictionary/${data.id}/items`,
    method: 'get',
    headers: { 'Accept-Language': 'zh' },
  })
}

// 字典-英文
export function dictionaryIDForEN(data: any) {
  return request({
    url: `api/dc/dictionary/${data.id}/items`,
    method: 'get',
    headers: { 'Accept-Language': 'en' },
  })
}

// 获取路由列表，请求头 配置 Authorization
export function currentUserTree(data: any) {
  return request({
    url: `api/dc/menu/current-user/tree`,
    method: 'get',
  })
}
