import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
// import WebLayout from '../views/layout/webLayout.vue' //左导航
import WebLayout from '../views/layout/webLayout2.vue' //上导航

/*
name: string    cacheView使用此name标识需缓存的组件名，vue默认使用文件名生成组件名或者使用defineOptiions显示配置组件名，最终需与此name保持一致
meta:{
  title:string        必填，页面名称，显示在标题栏
  roles:string[]      必填，可访问页面的权限列表。暂时设为['admin']，登录页面给所有用户设置了'admin'权限 TODO
  hidden?:boolean     可选，是否隐藏导航，通常：详情页面传参进入不显示在导航列表
  icon?:string        可选，使用左侧导航时，显示的导航图标。头部导航默认隐藏图标。使用 components/SvgIcon组件 + iconfont图标库项目
  alwaysShow?:boolean 可选，是否显示子导航，当只有一个 hidden不为true 的子元素时，设置是否渲染子导航。alwaysShow:true 渲染 el-sub-menu，否则渲染 el-menu
  keepAlive?:boolean  可选，是否缓存页面
}
children 下的子路由渲染在父组件下，父组件如果是WebLayout，webLayout.vue页面设置了<router-view>路由出口，自动渲染展示子路由。父组件如果不是WebLayout，应在组件内设置对应的<router-view>路由出口，否则不要配置成children嵌套关系

*/

//路由白名单维护
export const whiteNameList = ['WebLogin', 'Root', 'NotFound']
export const whitePathList = ['/login', '/404', '/webview', '/alarmDetail']

export const constantRoutes: Array<RouteRecordRaw> = [
  {
    path: '/login',
    name: 'WebLogin',
    component: () => import('../views/webLogin.vue'),
    meta: { title: '登录', hidden: true },
  },

  {
    path: '/404',
    name: 'NotFound',
    component: () => import('../views/NotFound.vue'),
    meta: { title: '404', hidden: true },
  },
  {
    path: '/webview',
    name: 'WebView',
    component: () => import('../views/WebView.vue'),
    meta: { title: 'webview', hidden: true },
  },
  {
    path: '/alarmDetail',
    name: 'AlarmDetail',
    component: () => import('../views/Alarm/AlarmDetail.vue'),
    meta: { title: '报警详情', hidden: true },
  },
  {
    path: '/',
    redirect: '/vehicleMonitor/index',
    name: 'Root',
    meta: { hidden: true },
  },
]

export const asyncRoutes: Array<RouteRecordRaw> = [
  {
    path: '/vehicleMonitor',
    component: WebLayout,
    redirect: '/vehicleMonitor/index',
    name: 'VehicleMonitor',
    meta: { title: '车辆实时监控', icon: 'icon-tubiao-bingtu', roles: ['admin'], keepAlive: true },
    children: [
      {
        path: '/vehicleMonitor/index',
        name: 'VehicleMonitorIndex',
        component: () => import('../views/Home/VehicleMonitor.vue'),
        meta: { title: '车辆实时监控', icon: 'icon-tubiao-bingtu', roles: ['admin'], keepAlive: true },
      },
    ],
  },
  {
    path: '/alarmList',
    component: WebLayout,
    redirect: '/alarmList/index',
    name: 'AlarmList',
    meta: { title: '报警界面', icon: 'icon-gaojing', roles: ['admin'] },
    children: [
      {
        path: '/alarmList/index',
        name: 'AlarmListIndex',
        component: () => import('../views/Alarm/AlarmList.vue'),
        meta: { title: '报警界面', icon: 'icon-gaojing', roles: ['admin'] },
      },
    ],
  },
  {
    path: '/dataAnalysis',
    component: WebLayout,
    redirect: '/dataAnalysis/index',
    name: 'DataAnalysis',
    meta: { title: '安全指标分析', icon: 'icon-tubiao-bingtu', roles: ['admin'], keepAlive: true },
    children: [
      {
        path: '/dataAnalysis/index',
        name: 'DataAnalysisIndex',
        component: () => import('../views/DataAnalysis.vue'),
        meta: { title: '安全指标分析', icon: 'icon-tubiao-bingtu', roles: ['admin'], keepAlive: true },
      },
    ],
  },
  {
    path: '/mobile-charging',
    name: 'MobileChargingStation',
    component: () => import('../views/BigScreen/MobileChargingStation.vue'),
    meta: { title: '移动充电桩大屏', hidden: true, roles: ['admin'] },
  },
  {
    path: '/report',
    component: WebLayout,
    redirect: '/report/index',
    name: 'Report',
    meta: { title: '报告', icon: 'icon-tubiao-bingtu', roles: ['admin'], hidden: true, keepAlive: true },
    children: [
      {
        path: '/report/index',
        name: 'ReportIndex',
        component: () => import('../views/Report/Report.vue'),
        meta: { title: '报告', icon: 'icon-tubiao-bingtu', roles: ['admin'], hidden: true, keepAlive: true },
      },
    ],
  },

  {
    path: '/workOrder',
    component: WebLayout,
    redirect: '/workOrder/list',
    name: 'WorkOrder',
    meta: { title: '工单', icon: 'icon-gongdan', roles: ['admin'] },
    children: [
      {
        path: '/workOrder/overview',
        name: 'WorkOrderOverview',
        component: () => import('../views/WorkOrder/WorkOrderOverview.vue'),
        meta: { title: '概览', icon: 'icon-gailan', roles: ['admin'] },
      },
      {
        path: '/workOrder/list',
        name: 'WorkOrderList',
        component: () => import('../views/WorkOrder/WorkOrderList.vue'),
        meta: { title: '工单列表', icon: 'icon-gongdan', roles: ['admin'], keepAlive: true },
      },
      {
        path: '/workOrder/detail',
        name: 'WorkOrderDetail',
        component: () => import('../views/WorkOrder/WorkOrderDetail.vue'),
        meta: { title: '工单详情', hidden: true },
      },
      {
        path: '/workOrder/case',
        name: 'CaseList',
        component: () => import('../views/WorkOrder/CaseList.vue'),
        meta: { title: '案例库', icon: 'icon-a-ziyuan652', roles: ['admin'] },
      },
    ],
  },

  {
    path: '/scene',
    component: WebLayout,
    redirect: '/scene/list',
    name: 'Scene',
    meta: { title: '场景联动', icon: 'icon-gongdan', roles: ['admin'] },
    children: [
      {
        path: '/scene/list',
        name: 'SceneList',
        component: () => import('../views/scene/scene-list.vue'),
        meta: { title: '场景联动' },
      },
      {
        path: '/scene/detail',
        name: 'SceneDetail',
        component: () => import('../views/scene/scene-detail.vue'),
        meta: { title: '详情', hidden: true },
      },
    ],
  },

  // {
  //   path: '/alarm',
  //   component: WebLayout,
  //   redirect: '/alarm/config',
  //   name: 'AlarmCenter2',
  //   meta: { title: '告警中心', icon: 'icon-gongdan', roles: ['admin'] },
  //   children: [
  //     {
  //       path: '/alarm/config-list',
  //       name: 'AlarmConfigList',
  //       component: () => import('../views/Alarm/config/alarm-config-list.vue'),
  //       meta: { title: '告警配置' },
  //     },
  //     {
  //       path: '/alarm/history-list',
  //       name: 'AlarmHistoryList',
  //       component: () => import('../views/Alarm/history/history-list.vue'),
  //       meta: { title: '告警列表' },
  //     },
  //   ],
  // },

  {
    path: '/notify',
    component: WebLayout,
    redirect: '/notify/template',
    name: 'Notify',
    meta: { title: '推送中心', icon: 'icon-tongzhi', roles: ['admin'] },
    children: [
      {
        path: '/notify/list',
        name: 'NotifyList',
        component: () => import('../views/notify/notify-list.vue'),
        meta: { title: '通知记录', hidden: true },
      },
      {
        path: '/template/list',
        name: 'TemplateList',
        component: () => import('../views/notify/stencil-list.vue'),
        meta: { title: '推送模版' },
      },
      // {
      //   path: '/template/config',
      //   name: 'TemplateConfig',
      //   component: () => import('../views/notify/stencil-config.vue'),
      //   meta: { title: '模版配置' },
      // },
    ],
  },

  {
    path: '/alarmCenter',
    component: WebLayout,
    redirect: '/alarmCenter/configuration',
    name: 'AlarmCenter',
    meta: { title: '告警中心', icon: '', roles: ['admin'] },
    children: [
      // {
      //   path: '/alarmCenter/configuration',
      //   name: 'AlarmConfiguration',
      //   component: () => import('../views/alarm-center/alarm-configuration.vue'),
      //   meta: { title: '告警配置' },
      // },
      {
        path: '/alarm/config-list',
        name: 'AlarmConfigList',
        component: () => import('../views/Alarm/config/alarm-config-list.vue'),
        meta: { title: '告警配置' },
      },
      {
        path: '/alarmCenter/detail',
        name: 'AlarmConfigurationDetail',
        component: () => import('../views/alarm-center/alarm-configuration-detail.vue'),
        meta: { title: '详情', hidden: true },
      },
      {
        path: '/alarm/history-list',
        name: 'AlarmHistoryList',
        component: () => import('../views/Alarm/history/history-list.vue'),
        meta: { title: '告警列表' },
      },
    ],
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes: [...constantRoutes],
})

export default router
