import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, isRef as _isRef, createSlots as _createSlots, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "download-blcok"
}
const _hoisted_2 = { class: "dialog-footer" }

import { ref, onMounted, computed, watch } from 'vue'
import type { UploadProps, UploadUserFile } from 'element-plus'
import { ElMessage } from 'element-plus'
import { Download, UploadFilled } from '@element-plus/icons-vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'FileUploadDialog',
  props: {
    visible: { type: Boolean, default: false },
    templeFileUrl: {},
    uploading: { type: Boolean, default: false }
  },
  emits: ['confirm', 'cancel', 'update:visible'],
  setup(__props: any, { expose: __expose, emit: __emit }) {

const props = __props

const emit = __emit

// 使用计算属性接收并更新父组件传入的visible属性，父组件使用 v-model 传入属性，自动监听 update:visible 事件
let dialogVisible = computed({
  get: () => props.visible,
  set: val => {
    emit('update:visible', val)
  },
})

// 弹框关闭，清空 fileList
const handleBeforeClose = () => {
  fileList.value = []
  // dialogVisible.value = false
}

// 上传组件
let fileList = ref([])
const onExceed: UploadProps['onExceed'] = (files, uploadFiles) => {
  console.log('onExceed', files, uploadFiles)
  ElMessage.error(`只能选择 1 个文件，当前共选择了 ${files.length + uploadFiles.length} 个`)
}
const onRemove: UploadProps['onRemove'] = (file, uploadFiles) => {
  console.log('handleRemove', file, uploadFiles, fileList.value)
}
const onChange: UploadProps['onChange'] = (file, uploadFiles) => {
  console.log('handleChange', file, uploadFiles)
  let flag = beforeUpload(file)
  if (flag) {
    fileList.value = [file.raw]
  }
}
// 不会触发？TODO
const onSuccess: UploadProps['onSuccess'] = (response, uploadFiles) => {
  console.log('onSuccess', response, uploadFiles)
}

// :auto-upload="false" 不会触发 :before-upload
const beforeUpload: UploadProps['beforeUpload'] = rawFile => {
  console.log('beforeUpload', rawFile)
  var suffixName = rawFile.name.split('.').pop()
  const isCorrectSuffix = ['xls', 'xlsx'].indexOf(suffixName) != -1
  const isLt10M = rawFile.size / 1024 / 1024 < 10
  if (!isCorrectSuffix) {
    ElMessage.error('上传文件只能是 xls、xlsx格式!')
  }
  if (!isLt10M) {
    ElMessage.error('上传文件大小不能超过 10MB!')
  }
  return isCorrectSuffix && isLt10M
}

// 取消按钮、抛出 cancel 事件，父组件根据需要监听事件添加对应处理逻辑
const handleCancelUpload = () => {
  dialogVisible.value = false
  fileList.value = []
  emit('cancel')
}

// 确认按钮
let uploading = ref(false)
const handleConfirmUpload = () => {
  emit('confirm', fileList.value[0])
}

// 对外暴露refresh方法，清空fileList，供父组件根据需要调用
const refresh = () => {
  fileList.value = []
}
__expose({
  refresh,
})

onMounted(() => {})

return (_ctx: any,_cache: any) => {
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_upload = _resolveComponent("el-upload")!
  const _component_el_link = _resolveComponent("el-link")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createBlock(_component_el_dialog, {
    title: "上传文件",
    modelValue: _unref(dialogVisible),
    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_isRef(dialogVisible) ? (dialogVisible).value = $event : dialogVisible = $event)),
    "close-on-click-modal": false,
    "close-on-press-escape": false,
    width: "600",
    onClose: handleBeforeClose
  }, {
    footer: _withCtx(() => [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_el_button, { onClick: handleCancelUpload }, {
          default: _withCtx(() => _cache[5] || (_cache[5] = [
            _createTextVNode("取消")
          ])),
          _: 1
        }),
        _createVNode(_component_el_button, {
          type: "primary",
          onClick: handleConfirmUpload,
          loading: _unref(uploading)
        }, {
          default: _withCtx(() => _cache[6] || (_cache[6] = [
            _createTextVNode(" 确认 ")
          ])),
          _: 1
        }, 8, ["loading"])
      ])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_el_upload, {
        drag: "",
        limit: 1,
        "file-list": _unref(fileList),
        "onUpdate:fileList": _cache[0] || (_cache[0] = ($event: any) => (_isRef(fileList) ? (fileList).value = $event : fileList = $event)),
        accept: ".xls, .xlsx",
        "auto-upload": false,
        "before-upload": beforeUpload,
        "on-exceed": onExceed,
        "on-change": onChange,
        "on-success": onSuccess,
        "on-remove": onRemove,
        action: ""
      }, _createSlots({
        trigger: _withCtx(() => [
          _createVNode(_component_el_icon, { class: "el-icon--upload" }, {
            default: _withCtx(() => [
              _createVNode(_unref(UploadFilled))
            ]),
            _: 1
          }),
          _cache[2] || (_cache[2] = _createElementVNode("div", {
            class: "el-upload__text",
            style: {"font-size":"16px"}
          }, [
            _createTextVNode(" 将文件拖到此处，或 "),
            _createElementVNode("em", { style: {"font-size":"16px"} }, "点击上传")
          ], -1))
        ]),
        _: 2
      }, [
        (!!props.templeFileUrl)
          ? {
              name: "tip",
              fn: _withCtx(() => [
                _cache[3] || (_cache[3] = _createElementVNode("div", { class: "el-upload__tip" }, "请按照文件模板上传正确文件(*.xls,.xlsx)，限制10M", -1))
              ]),
              key: "0"
            }
          : undefined
      ]), 1032, ["file-list"]),
      (!!props.templeFileUrl)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_el_link, {
              type: "primary",
              href: props.templeFileUrl,
              icon: _unref(Download)
            }, {
              default: _withCtx(() => _cache[4] || (_cache[4] = [
                _createTextVNode("下载文件模板")
              ])),
              _: 1
            }, 8, ["href", "icon"])
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["modelValue"]))
}
}

})