import { dictionaryIDForZHCN, dictionaryIDForEN } from '@/api/common'
import { BaseObject } from '@/types'

// 获取国际化使用的 中文+英文 字典  TODO
export const useGetEnDictionary = async () => {
  const warnNameMap: BaseObject = {}
  const promiseArr = [dictionaryIDForZHCN({ id: 'warn-name-enum' }), dictionaryIDForEN({ id: 'warn-name-enum' })] //TODO
  const list = await Promise.all(promiseArr)
    .then(res => res)
    .catch(error => {
      console.log('CommonSearch-dictionaryID-error', error)
    })
  if (list?.length) {
    if (list[0]?.data?.length) {
      list[0].data.map((item: any) => {
        warnNameMap[item.value] = { ...item, textZH: item.text }
      })
    }
    if (list[1]?.data?.length) {
      list[1].data.map((item: any) => {
        warnNameMap[item.value].textEN = item.text
      })
    }
  }
  console.log('useGetEnDictionary', list, { warnNameMap })
  if (list?.length && list.every(item => item?.data)) {
    return {
      warnNameMap,
    }
  } else {
    return undefined
  }
}
