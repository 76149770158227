import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, unref as _unref, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, KeepAlive as _KeepAlive } from "vue"

const _hoisted_1 = { class: "main-page" }
const _hoisted_2 = { class: "header-right" }
const _hoisted_3 = { class: "user-info" }
const _hoisted_4 = {
  key: 0,
  class: "name"
}

import { ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { DataAnalysis, UserFilled } from '@element-plus/icons-vue'
import NavMenu from './NavMenu.vue'
import ChangeLang from '@/components/change-lang.vue'

import { useUserStore } from '@/store/base'
import { usePermissionStore } from '@/store/permission'
import pinia from '@/store'


export default /*@__PURE__*/_defineComponent({
  __name: 'webLayout2',
  setup(__props) {

const isCollapse = ref(false)
const handleChangeCollapse = () => {
  isCollapse.value = !isCollapse.value
}

// 跳转大屏
const router = useRouter()
const toBigScreen = () => {
  router.push('/mobile-charging')
}

// 用户信息
let userStore = useUserStore(pinia)
let permissionStore = usePermissionStore(pinia)
let username = userStore.userInfo.username || ref('')

const cachedViews = permissionStore.cachedViews

const handleQuit = () => {
  userStore.clearUserInfo()
  permissionStore.clearPermission()
  permissionStore.resetRouter(router)
  router.replace('/login')
}

return (_ctx: any,_cache: any) => {
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_avatar = _resolveComponent("el-avatar")!
  const _component_el_dropdown_item = _resolveComponent("el-dropdown-item")!
  const _component_el_dropdown_menu = _resolveComponent("el-dropdown-menu")!
  const _component_el_dropdown = _resolveComponent("el-dropdown")!
  const _component_el_header = _resolveComponent("el-header")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_el_main = _resolveComponent("el-main")!
  const _component_el_container = _resolveComponent("el-container")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_container, null, {
      default: _withCtx(() => [
        _createVNode(_component_el_header, { class: "page-header" }, {
          default: _withCtx(() => [
            _createVNode(NavMenu, {
              isCollapse: isCollapse.value,
              mode: "horizontal"
            }, null, 8, ["isCollapse"]),
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_el_button, {
                text: "",
                icon: _unref(DataAnalysis),
                onClick: toBigScreen
              }, {
                default: _withCtx(() => _cache[0] || (_cache[0] = [
                  _createTextVNode("大屏")
                ])),
                _: 1
              }, 8, ["icon"]),
              _createVNode(_component_el_dropdown, null, {
                dropdown: _withCtx(() => [
                  _createVNode(_component_el_dropdown_menu, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_dropdown_item, { onClick: handleQuit }, {
                        default: _withCtx(() => _cache[1] || (_cache[1] = [
                          _createTextVNode("退出系统")
                        ])),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_3, [
                    _createVNode(_component_el_avatar, {
                      size: 26,
                      icon: _unref(UserFilled)
                    }, null, 8, ["icon"]),
                    (_unref(username))
                      ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_unref(username)), 1))
                      : _createCommentVNode("", true)
                  ])
                ]),
                _: 1
              })
            ])
          ]),
          _: 1
        }),
        _createVNode(_component_el_main, { class: "page-content" }, {
          default: _withCtx(() => [
            _createVNode(_component_router_view, null, {
              default: _withCtx(({ Component, route }) => [
                (_openBlock(), _createBlock(_KeepAlive, { include: _unref(cachedViews) }, [
                  (_openBlock(), _createBlock(_resolveDynamicComponent(Component), {
                    key: route.fullPath
                  }))
                ], 1032, ["include"]))
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}
}

})