<template>
  <el-config-provider :locale="currentLang">
    <router-view />
  </el-config-provider>
</template>

<script setup lang="ts">
import { ref, computed, watch } from 'vue'
import { ElConfigProvider } from 'element-plus'
import { useLangStore } from './store/lang'
import pinia from '@/store'

import zhCn from 'element-plus/dist/locale/zh-cn.mjs' //最新路径
import En from 'element-plus/dist/locale/en.mjs'

import { useI18n } from 'vue-i18n'
const { locale } = useI18n()

const langStore = useLangStore(pinia)

let currentLang = computed(() => {
  return langStore.lang === 'zhCN' ? zhCn : En
})

// 监听语言切换重新给locale.value赋值，刷新页面 locale.value丢失重置为默认值。放在APP.vue的setup，没有引入change-lang.vue组件的页面也可同步全局语言。
watch(
  () => langStore.lang,
  newValue => {
    locale.value = newValue
  },
  {
    immediate: true,
  },
)
</script>

<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
