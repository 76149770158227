import { ref, reactive, Ref, ComponentInternalInstance } from 'vue'
import { BaseObject, SearchItem, TableData, PageData } from '@/types/index'
import { cloneDeep } from 'lodash-es'
import { dictionaryID } from '@/api/common'

// CommonPagination组件数据

// 使用嵌套的reactive对象，从业务页面作为一个属性整体传入CommonPagination，props属性下嵌套的属性可修改且保留响应式
export function usePage(cb?: () => void, initPageSize?: number) {
  const pageData: PageData = reactive({
    pageNum: 1,
    pageSize: initPageSize || 10,
    totalCount: 0,
  })
  const handlePageChange = () => {
    cb && cb()
  }
  return { pageData, handlePageChange }
}

// CommonSearch组件数据
export function useSearch(rawObj: BaseObject, cb: () => void, pageNum?: Ref) {
  // 表单数据，此处使用深拷贝，保证表单不修改rawObj原始值，留待重置数据时使用
  const propsValue = reactive(cloneDeep(rawObj))
  // 重置按钮统一处理方法：数据赋为空值，避免非必要的 formEl.resetFields() DOM操作
  const handleResetAndQuery = () => {
    for (const key in rawObj) {
      propsValue[key] = rawObj[key]
    }
    if (pageNum) {
      pageNum.value = 1
    }
    cb()
  }
  return { propsValue, handleResetAndQuery }
}

// CommonTable组件数据
export function useTable() {
  const tableData: TableData = reactive({
    tableList: [],
    multipleSelection: [],
  })
  return tableData
}

// 数据字典
export function useDataDictionary(code: string | string[], propsList?: Ref<SearchItem[]>): Promise<any> {
  return new Promise((resolve, reject) => {
    if (!code.length) {
      resolve([])
    }
    const codeList = typeof code == 'string' ? [code] : code
    const promiseArr: any[] = []
    codeList.map(code => {
      promiseArr.push(dictionaryID({ id: code }))
    })
    Promise.all(promiseArr)
      .then(resList => {
        const result = resList.map(res =>
          res.data && res.data.length ? res.data.map((item: BaseObject) => ({ ...item, label: item.text })) : [],
        )
        if (propsList) {
          codeList.map((code, index) => {
            const item = propsList.value.find(item => item.valueSetCode == code)
            if (item) {
              item.options = result[index]
            }
          })
        }
        if (typeof code == 'string') {
          resolve(result[0])
        } else {
          resolve(result)
        }
      })
      .catch(error => {
        reject(error)
      })
  })
}

// loading
export function useLoading() {
  const loading = ref(false)
  const setLoading = (val: boolean) => {
    loading.value = val
  }
  return { loading, setLoading }
}

// 全局常量 ComponentInternalInstance
export function useGlobalData(instance: ComponentInternalInstance | null) {
  return instance?.appContext.config.globalProperties?.ConstantData || {}
}

// 批量赋值，限制keys为obj的属性集合
export function useBatchAssignValue<T extends object, K extends keyof T>(obj: T, keys: K[], data: any) {
  for (const key of keys) {
    obj[key] = data[key]
  }
  return obj
}
