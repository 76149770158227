import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, createBlock as _createBlock, createSlots as _createSlots, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "common-form-component" }
const _hoisted_2 = { class: "el-form-item__label custom-label" }

import { onMounted, reactive, ref, toRefs, onBeforeMount, watch, nextTick } from 'vue'
import type { FormInstance, FormRules, FormItemRule } from 'element-plus'
import { BaseObject } from '@/types/index'
import ImgUpload from './ImgUpload.vue'
import FileUpload from './FileUpload.vue'
import FileUploadDialog from './FileUploadDialog.vue'
import { dictionaryID } from '@/api/common'

// el-select options 选项类型
interface OptionItem {
  label?: string
  value?: any
  [index: string]: any
}
// el-option label/value 使用其他字段名的映射
interface OptionItemKeysMap {
  label: string
  value: string
}

// el-form-item 数据类型
interface FormItem {
  type: '10' | '11' | '20' | '21' | '22' | '23' | '30' | '40' | '50' | '60' | '70' | '80' | '81' | '82' // 10:el-input、 11:el-input.textarea， 20:el-date-picker[daterange]、21:el-date-picker[datetime]、22:el-date-picker[date]、23:el-date-picker[datetimerange]， 30:el-select， 50:el-autocomplete， 60复选框，  70:单选框， 80:图片上传、81文件上传、82文件上传弹框
  label: string // 表单项 label
  prop: string // 表单项 prop
  clearable?: boolean // 表单项 是否可清除，默认 true
  options?: OptionItem[] // 表单项是el-select且选项是值集时，查询到的选项列表，接口数据结构 [{value:'', name:''}]
  valueSetCode?: string // 表单项是el-select且选项是值集时，查询值集的code
  optionItemKeysMap?: OptionItemKeysMap // 表单项是el-select且选项非值集时，<el-option>使用的属性的映射
  slotName?: string // 插槽名，有值即表示使用插槽
  disabled?: boolean // 是否禁用
  readonly?: boolean // 是否只读
  elColSpan?: number // el-form-item :span的值
  showAllLevels?: boolean // 表单项是el-cascader时，show-all-levels
  queryFetch?: (queryString: string, cb: any) => void // 表单项是el-autocomplete时，fetch-suggestions
  maxLength?: number
  showWordLimit?: boolean
  labelDesc?: string //el-form-item label 描述信息
  buttonText?: string //80上传组件，按钮文字
  rows?: number //11 textarea
  minRows?: number //11 textarea
  autoSize?: {
    type: object
    default: () => { minRows: 2 } //minRows: 2; maxRows: 4
  } //11 textarea
  filterable?: boolean //30 el-select
  allowCreate?: boolean //30 el-select
  defaultFirstOption?: boolean //30 el-select
  rules?: FormItemRule //表单项rules
  required?: boolean //根据type自动生成对应的必填rules
}

type ShowBtn = 'both' | 'confirm' | 'cancel' | 'none'

// 如何定义PropsValue的对象类型，使包含所有propsList的prop，运行时确定propsList，无法校验？？？TODO
// interface PropsValue<T extends FormItem> {
//   [p in keyof T]:any
// }
// props的类型声明、默认值。（vue3要求，类型声明参数：类型字面量；在同一文件中的接口或类型字面量的引用）

export default /*@__PURE__*/_defineComponent({
  __name: 'CommonForm',
  props: {
    propsList: { default: () => [] },
    propsValue: { default: () => ({}) },
    labelWidth: { default: 'auto' },
    elColSpan: { default: 6 },
    showLable: { type: Boolean, default: true },
    rules: {},
    btnPlacement: { default: 'left' },
    labelPosition: { default: 'top' },
    clearable: { type: Boolean, default: true },
    disabled: { type: Boolean, default: false },
    readonly: { type: Boolean, default: false },
    showBtn: { default: 'both' },
    loading: { type: Boolean, default: false },
    confirmButtonText: { default: '保存' },
    cancelButtonText: { default: '取消' }
  },
  emits: ['cancel', 'confirm'],
  setup(__props: any, { expose: __expose, emit: __emit }) {

const props = __props
// let formData = toRefs(props.propsValue) //defineProps声明的属性，不可修改，vue已设置为readonly，但是props.key.key可修改。propsValue是在父组件声明的reactive数据，传入子组件保持响应式
let formData = props.propsValue //formRef.value?.validate校验的值是原始reactive值，toRefs包括后无法校验

const emit = __emit //emit 第一个参数被vue定义为defineEmits的联合类型

const formRef = ref<FormInstance>()

// 获取表单项禁用状态，表单项配置优先于表单配置，默认false
const getDisabled = (item: FormItem) => {
  if ('disabled' in item) {
    return !!item.disabled
  }
  if ('disabled' in props) {
    return !!props.disabled
  }
  return false
}

const getReadonly = (item: FormItem) => {
  if ('readonly' in item) {
    return !!item.readonly
  }
  if ('readonly' in props) {
    return !!props.readonly
  }
  return false
}

const getClearable = (item: FormItem) => {
  if ('clearable' in item) {
    return !!item.clearable
  }
  if ('clearable' in props) {
    return !!props.clearable
  }
  return true
}

//生成表单项校验规则
const getFormItemRules = (item: FormItem) => {
  if (item.rules) {
    return item.rules
  } else if (item.required) {
    let arr: any = []
    if (item.type === '10' || item.type === '11') {
      arr = [{ required: true, message: '请填写', trigger: 'blur' }]
    } else if (['20', '21', '22', '23', '30', '50', '60', '70'].indexOf(item.type) != -1) {
      arr = [{ required: true, message: '请选择', trigger: 'change' }]
    }
    return arr
  }
  return undefined
}

// 重置表单
const resetFields = () => {
  formRef.value?.resetFields()
}
// 清空输入校验
const clearValidate = () => {
  formRef.value?.clearValidate()
}
// 表单校验，返回promise，使用await获取结果
const validateFn = () => {
  return formRef.value?.validate(valid => {
    if (valid) {
      return true
    } else {
      return false
    }
  })
}
// 向父组件暴露当前住组件的方法
__expose({
  resetFields,
  clearValidate,
  validateFn,
})

// 80 文件上传 为各个表单项组件设置ref
let refMap = reactive<BaseObject>({})
const setRef = (el, item) => {
  refMap[item.prop] = el
}

// 取消按钮
const handleCancel = () => {
  emit('cancel')
  clearValidate()
}

// 保存按钮
const handleSave = () => {
  formRef.value?.validate(valid => {
    if (valid) {
      nextTick(async () => {
        await refMap.file?.confirmUpload() //默认当前只注册了一个上传组件，且表单项prop值是file TODO
        emit('confirm', formData)
      })
    } else {
      // ElMessage.error('请检查输入')
    }
  })
}

// 上传组件80：文件上传成功回调
const uploadSuccessCB = (data: any) => {
  console.log('uploadSuccessCB')
  formData.file = data || {}
}

onBeforeMount(() => {
  // 数据字典：el-select 选项值集，构造数据结构[{label:'',value:''}]。
  props.propsList.map(item => {
    if (item.valueSetCode) {
      dictionaryID({ id: item.valueSetCode })
        .then((res: any) => {
          if (res.data && res.data.length) {
            item.options = res.data.map((item: any) => ({ ...item, label: item.text }))
          } else {
            item.options = []
          }
        })
        .catch(error => {
          console.log('CommonSearch-dictionaryID-error', error)
        })
    }
  })
})
onMounted(() => {})

return (_ctx: any,_cache: any) => {
  const _component_QuestionFilled = _resolveComponent("QuestionFilled")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_popover = _resolveComponent("el-popover")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_date_picker = _resolveComponent("el-date-picker")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_autocomplete = _resolveComponent("el-autocomplete")!
  const _component_el_checkbox = _resolveComponent("el-checkbox")!
  const _component_el_checkbox_group = _resolveComponent("el-checkbox-group")!
  const _component_el_radio = _resolveComponent("el-radio")!
  const _component_el_radio_group = _resolveComponent("el-radio-group")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_form = _resolveComponent("el-form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_form, {
      ref_key: "formRef",
      ref: formRef,
      model: _unref(formData),
      rules: props.rules,
      "label-position": _ctx.labelPosition,
      "label-width": _ctx.labelWidth
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.propsList, (item, index) => {
          return (_openBlock(), _createBlock(_component_el_form_item, {
            key: item.prop,
            "label-width": props.labelWidth,
            label: props.showLable ? item.label : '',
            prop: item.prop,
            rules: getFormItemRules(item)
          }, _createSlots({
            default: _withCtx(() => [
              (item.slotName)
                ? _renderSlot(_ctx.$slots, item.slotName, {
                    key: 0,
                    prop: item
                  })
                : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                    (item.type == '10')
                      ? (_openBlock(), _createBlock(_component_el_input, {
                          key: 0,
                          modelValue: _unref(formData)[item.prop],
                          "onUpdate:modelValue": ($event: any) => ((_unref(formData)[item.prop]) = $event),
                          placeholder: '请输入' + item.label,
                          clearable: getClearable(item),
                          disabled: getDisabled(item),
                          readonly: getReadonly(item),
                          maxlength: item.maxLength ?? 100
                        }, null, 8, ["modelValue", "onUpdate:modelValue", "placeholder", "clearable", "disabled", "readonly", "maxlength"]))
                      : _createCommentVNode("", true),
                    (item.type == '11')
                      ? (_openBlock(), _createBlock(_component_el_input, {
                          key: 1,
                          type: "textarea",
                          modelValue: _unref(formData)[item.prop],
                          "onUpdate:modelValue": ($event: any) => ((_unref(formData)[item.prop]) = $event),
                          placeholder: '请输入' + item.label,
                          clearable: getClearable(item),
                          disabled: getDisabled(item),
                          readonly: getReadonly(item),
                          "show-word-limit": !!(item.showWordLimit ?? (item.maxLength ? true : false)),
                          maxlength: item.maxLength,
                          rows: item.rows,
                          "auto-size": !item.rows ? { minRows: item.minRows ?? 2 } : undefined
                        }, null, 8, ["modelValue", "onUpdate:modelValue", "placeholder", "clearable", "disabled", "readonly", "show-word-limit", "maxlength", "rows", "auto-size"]))
                      : _createCommentVNode("", true),
                    (item.type == '20')
                      ? (_openBlock(), _createBlock(_component_el_date_picker, {
                          key: 2,
                          type: "daterange",
                          modelValue: _unref(formData)[item.prop],
                          "onUpdate:modelValue": ($event: any) => ((_unref(formData)[item.prop]) = $event),
                          "value-format": "YYYY-MM-DD",
                          "start-placeholder": "开始时间",
                          "end-placeholder": "结束时间",
                          clearable: getClearable(item),
                          disabled: getDisabled(item)
                        }, null, 8, ["modelValue", "onUpdate:modelValue", "clearable", "disabled"]))
                      : _createCommentVNode("", true),
                    (item.type == '21')
                      ? (_openBlock(), _createBlock(_component_el_date_picker, {
                          key: 3,
                          type: "datetime",
                          modelValue: _unref(formData)[item.prop],
                          "onUpdate:modelValue": ($event: any) => ((_unref(formData)[item.prop]) = $event),
                          "value-format": "YYYY-MM-DD HH:mm:ss",
                          clearable: getClearable(item),
                          disabled: getDisabled(item)
                        }, null, 8, ["modelValue", "onUpdate:modelValue", "clearable", "disabled"]))
                      : _createCommentVNode("", true),
                    (item.type == '22')
                      ? (_openBlock(), _createBlock(_component_el_date_picker, {
                          key: 4,
                          type: "date",
                          modelValue: _unref(formData)[item.prop],
                          "onUpdate:modelValue": ($event: any) => ((_unref(formData)[item.prop]) = $event),
                          "value-format": "YYYY-MM-DD",
                          clearable: getClearable(item),
                          disabled: getDisabled(item)
                        }, null, 8, ["modelValue", "onUpdate:modelValue", "clearable", "disabled"]))
                      : _createCommentVNode("", true),
                    (item.type == '23')
                      ? (_openBlock(), _createBlock(_component_el_date_picker, {
                          key: 5,
                          type: "datetimerange",
                          modelValue: _unref(formData)[item.prop],
                          "onUpdate:modelValue": ($event: any) => ((_unref(formData)[item.prop]) = $event),
                          "value-format": "YYYY-MM-DD HH:mm:ss",
                          clearable: getClearable(item),
                          disabled: getDisabled(item)
                        }, null, 8, ["modelValue", "onUpdate:modelValue", "clearable", "disabled"]))
                      : _createCommentVNode("", true),
                    (item.type == '30')
                      ? (_openBlock(), _createBlock(_component_el_select, {
                          key: 6,
                          modelValue: _unref(formData)[item.prop],
                          "onUpdate:modelValue": ($event: any) => ((_unref(formData)[item.prop]) = $event),
                          placeholder: '请选择' + item.label,
                          clearable: getClearable(item),
                          disabled: getDisabled(item),
                          filterable: item.filterable,
                          "allow-create": item.allowCreate,
                          "default-first-option": item.defaultFirstOption
                        }, {
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.options, (optionItem, optionItemIndex) => {
                              return (_openBlock(), _createBlock(_component_el_option, {
                                key: optionItemIndex,
                                label: optionItem[item?.optionItemKeysMap?.label || 'label'],
                                value: optionItem[item?.optionItemKeysMap?.value || 'value']
                              }, null, 8, ["label", "value"]))
                            }), 128))
                          ]),
                          _: 2
                        }, 1032, ["modelValue", "onUpdate:modelValue", "placeholder", "clearable", "disabled", "filterable", "allow-create", "default-first-option"]))
                      : _createCommentVNode("", true),
                    (item.type == '50')
                      ? (_openBlock(), _createBlock(_component_el_autocomplete, {
                          key: 7,
                          modelValue: _unref(formData)[item.prop],
                          "onUpdate:modelValue": ($event: any) => ((_unref(formData)[item.prop]) = $event),
                          "fetch-suggestions": item.queryFetch,
                          placeholder: '请选择' + item.label,
                          clearable: getClearable(item),
                          disabled: getDisabled(item)
                        }, null, 8, ["modelValue", "onUpdate:modelValue", "fetch-suggestions", "placeholder", "clearable", "disabled"]))
                      : _createCommentVNode("", true),
                    (item.type == '60')
                      ? (_openBlock(), _createBlock(_component_el_checkbox_group, {
                          key: 8,
                          modelValue: _unref(formData)[item.prop],
                          "onUpdate:modelValue": ($event: any) => ((_unref(formData)[item.prop]) = $event),
                          disabled: getDisabled(item)
                        }, {
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.options, (optionItem, optionItemIndex) => {
                              return (_openBlock(), _createBlock(_component_el_checkbox, {
                                key: optionItemIndex,
                                label: optionItem[item?.optionItemKeysMap?.value || 'value']
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(optionItem[item?.optionItemKeysMap?.label || 'label']), 1)
                                ]),
                                _: 2
                              }, 1032, ["label"]))
                            }), 128))
                          ]),
                          _: 2
                        }, 1032, ["modelValue", "onUpdate:modelValue", "disabled"]))
                      : _createCommentVNode("", true),
                    (item.type == '70')
                      ? (_openBlock(), _createBlock(_component_el_radio_group, {
                          key: 9,
                          modelValue: _unref(formData)[item.prop],
                          "onUpdate:modelValue": ($event: any) => ((_unref(formData)[item.prop]) = $event),
                          disabled: getDisabled(item)
                        }, {
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.options, (optionItem, optionItemIndex) => {
                              return (_openBlock(), _createBlock(_component_el_radio, {
                                key: optionItemIndex,
                                label: optionItem[item?.optionItemKeysMap?.value || 'value']
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(optionItem[item?.optionItemKeysMap?.label || 'label']), 1)
                                ]),
                                _: 2
                              }, 1032, ["label"]))
                            }), 128))
                          ]),
                          _: 2
                        }, 1032, ["modelValue", "onUpdate:modelValue", "disabled"]))
                      : _createCommentVNode("", true),
                    (item.type == '80')
                      ? (_openBlock(), _createBlock(ImgUpload, {
                          key: 10,
                          "file-list": _unref(formData)[item.prop],
                          "onUpdate:fileList": ($event: any) => ((_unref(formData)[item.prop]) = $event),
                          disabled: getDisabled(item)
                        }, null, 8, ["file-list", "onUpdate:fileList", "disabled"]))
                      : _createCommentVNode("", true),
                    (item.type == '81')
                      ? (_openBlock(), _createBlock(FileUpload, {
                          key: 11,
                          ref_for: true,
                          ref: el => setRef(el, item),
                          "button-text": item.buttonText,
                          onUploadSuccess: uploadSuccessCB
                        }, null, 8, ["button-text"]))
                      : _createCommentVNode("", true),
                    (item.type == '82')
                      ? (_openBlock(), _createBlock(FileUploadDialog, {
                          key: 12,
                          file: _unref(formData)[item.prop],
                          "onUpdate:file": ($event: any) => ((_unref(formData)[item.prop]) = $event),
                          disabled: getDisabled(item)
                        }, null, 8, ["file", "onUpdate:file", "disabled"]))
                      : _createCommentVNode("", true)
                  ], 64))
            ]),
            _: 2
          }, [
            (item.labelDesc)
              ? {
                  name: "label",
                  fn: _withCtx(() => [
                    _createElementVNode("div", _hoisted_2, [
                      _createTextVNode(_toDisplayString(item.label) + " ", 1),
                      _createVNode(_component_el_popover, {
                        placement: "top-start",
                        width: 300,
                        trigger: "hover",
                        content: item.labelDesc || '',
                        "popper-style": "fontSize:12px"
                      }, {
                        reference: _withCtx(() => [
                          _createElementVNode("span", null, [
                            _createVNode(_component_el_icon, { size: 15 }, {
                              default: _withCtx(() => [
                                _createVNode(_component_QuestionFilled)
                              ]),
                              _: 1
                            })
                          ])
                        ]),
                        _: 2
                      }, 1032, ["content"])
                    ])
                  ]),
                  key: "0"
                }
              : undefined
          ]), 1032, ["label-width", "label", "prop", "rules"]))
        }), 128)),
        (!props.readonly && !props.disabled && ['both', 'confirm', 'cancel'].indexOf(props.showBtn) !== -1)
          ? (_openBlock(), _createBlock(_component_el_form_item, {
              key: 0,
              "label-width": props.labelWidth,
              label: "",
              class: _normalizeClass(['btn-block', props.btnPlacement])
            }, {
              default: _withCtx(() => [
                (props.showBtn === 'both' || props.showBtn === 'confirm')
                  ? (_openBlock(), _createBlock(_component_el_button, {
                      key: 0,
                      type: "primary",
                      size: "default",
                      loading: props.loading,
                      onClick: handleSave
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.confirmButtonText), 1)
                      ]),
                      _: 1
                    }, 8, ["loading"]))
                  : _createCommentVNode("", true),
                (props.showBtn === 'both' || props.showBtn === 'cancel')
                  ? (_openBlock(), _createBlock(_component_el_button, {
                      key: 1,
                      size: "default",
                      onClick: handleCancel
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.cancelButtonText), 1)
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["label-width", "class"]))
          : _createCommentVNode("", true)
      ]),
      _: 3
    }, 8, ["model", "rules", "label-position", "label-width"])
  ]))
}
}

})