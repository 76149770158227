import { createWebsocket } from '@/utils/websocket'
import { useSceneStore } from '@/store/scene'
import pinia from '@/store'

const sceneStore = useSceneStore(pinia)

// 全局 /dc/路径下 websocket
let dcWS: any = undefined
export const useDCWebsocket = () => {
  // console.log('useDCWebsocket--', dcWS)
  if (!dcWS) {
    // 刷新页面重建WS，清除之前的缓存（两次刷新页面，不保存上次的数据，不使用sessionStorage）
    // sessionStorage.removeItem('messageData')
    // sessionStorage.removeItem('logData')
    dcWS = createWebsocket(
      'wss://api.gotiondigitalpower.com/api/dc/messaging',
      undefined,
      (res: any) => {
        // console.log('dcWS--success', res)

        // const messageStorageData = sessionStorage.getItem('messageData')
        // let messageList = []
        // if (messageStorageData) {
        //   messageList = JSON.parse(messageStorageData) ?? []
        // }
        const messageList = sceneStore.messageData ? [...sceneStore.messageData] : [] //每次替换对象，保证触发 watch --> sceneStore.messageData
        const isEvent = res.topic?.indexOf('/event/') !== -1
        if (isEvent) {
          messageList.unshift(res)
          sceneStore.updateMessageData(messageList)
          // sessionStorage.setItem('messageData', JSON.stringify(messageList))
        }

        // const logStorageData = sessionStorage.getItem('logData')
        // let logList = []
        // if (logStorageData) {
        //   logList = JSON.parse(logStorageData) ?? []
        // }
        const logList = sceneStore.logData ? [...sceneStore.logData] : []
        const isLog = res.topic?.indexOf('/logger/') !== -1
        if (isLog) {
          logList.unshift(res)
          sceneStore.updateLogData(logList)
          // sessionStorage.setItem('logData', JSON.stringify(logList))
        }
      },
      (error: any) => {
        console.log('dcWS--error', error)
      },
    )
  }

  return dcWS
}

export const usetCloseDCWebsocket = () => {
  dcWS && dcWS.closeWebsocket?.()
  dcWS = undefined
}
