<template>
  <el-upload
    ref="uploadRef"
    :limit="1"
    v-model:file-list="fileList"
    accept=".bin,.tar,.gz,.xz,.zip,.gzip,.apk,.dav,.pack"
    :auto-upload="false"
    :http-request="confirmUpload"
    :on-exceed="onExceed"
    :before-upload="beforeUpload"
    :on-change="onChange"
    :on-success="onSuccess"
    :on-remove="onRemove"
    action=""
  >
    <template #trigger>
      <el-button>{{ props.buttonText ?? '上传文件' }}</el-button>
    </template>
  </el-upload>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue'
import type { UploadProps, UploadInstance } from 'element-plus'
import { ElMessage } from 'element-plus'

import { BaseObject } from '@/types'
// import { otaFirmwareUrl, otaFirmwareUpload } from '@/api/ota'
// import { useEquipmentStore } from '@/store/Equipment/product'
// const Equipmentstore = useEquipmentStore()

const props = withDefaults(
  defineProps<{
    buttonText?: string //上传按钮文字
  }>(),
  {},
)

const emit = defineEmits(['uploadSuccess', 'uploadCancel', 'confirm'])

let uploadRef = ref<UploadInstance>()

// 上传组件
let fileList = ref([])

// 文件数量超出
const onExceed: UploadProps['onExceed'] = (files, uploadFiles) => {
  console.log('onExceed', files, uploadFiles)
  ElMessage.error(`只能选择 1 个文件，当前共选择了 ${files.length + uploadFiles.length} 个`)
}

// 移除
const onRemove: UploadProps['onRemove'] = (file, uploadFiles) => {
  console.log('onRemove', file, uploadFiles, fileList.value)
}

// 文件状态改变时的钩子，添加文件、上传成功和上传失败时都会被调用
let fileSize = ref(0)
const onChange: UploadProps['onChange'] = (file, uploadFiles) => {
  console.log('onChange', file, uploadFiles)
  let flag = beforeUpload(file)
  if (flag) {
    fileList.value = [file.raw]
    fileSize.value = file.size
  }
}

// 文件上传成功时的钩子。不会触发？TODO
const onSuccess: UploadProps['onSuccess'] = (response, uploadFiles) => {
  console.log('onSuccess', response, uploadFiles)
}

// 上传文件之前的钩子，返回false阻止上传。
let fileSuffix = ref('')
const beforeUpload: UploadProps['beforeUpload'] = rawFile => {
  console.log('beforeUpload', rawFile)
  var suffixName = rawFile.name.split('.').pop()!
  fileSuffix.value = suffixName
  const isCorrectSuffix = ['bin', 'tar', 'gz', 'xz', 'zip', 'gzip', 'apk', 'dav', 'pack'].indexOf(suffixName) != -1
  const isLt100M = rawFile.size / 1024 / 1024 < 100
  if (!isCorrectSuffix) {
    ElMessage.error('上传文件只能是 xls、xlsx格式!')
  }
  if (!isLt100M) {
    ElMessage.error('上传文件大小不能超过 100MB!')
  }
  return isCorrectSuffix && isLt100M
}

// http-request 覆盖默认的 Xhr 行为，允许自行实现上传文件的请求。不能自动触发？TODO 由父组件手动调用确定上传时机
let uploading = ref(false)
// 获取上传参数
const confirmUpload = () => {
  console.log('confirmUpload', fileList.value)
  // emit('confirm', fileList.value[0])
  // return otaFirmwareUrl({ iotInstanceId: Equipmentstore.iotId, fileSuffix: fileSuffix.value })
  //   .then(res => {
  //     return uploadFile(res.data.body.data)
  //   })
  //   .catch(error => {
  //     console.log(error)
  //     uploading.value = false
  //     ElMessage.warning('请求出错')
  //   })
}

// 上传文件
const uploadFile = (params: BaseObject) => {
  /* 
  firmwareUrl: "https://iotx-ota.oss-cn-shanghai.aliyuncs.com/ota/42d34254275b965a5d8f1333f5bc07ff/2BF605445D22403E9455BA312AD0382E.zip"
host: "https://iotx-ota.oss-cn-shanghai.aliyuncs.com"
key: "ota/42d34254275b965a5d8f1333f5bc07ff/2BF605445D22403E9455BA312AD0382E.zip"
objectStorage: "OSS"
ossaccessKeyId: "LTAI4G1TuWwSirnbAzUHfL3e"
policy: "eyJleHBpcmF0aW9uIjoiMjAyMy0wNS0yNlQwMjoyNzowMC4zMzdaIiwiY29uZGl0aW9ucyI6W1siY29udGVudC1sZW5ndGgtcmFuZ2UiLDAsMTA0ODU3NjAwMF0seyJrZXkiOiJvdGEvNDJkMzQyNTQyNzViOTY1YTVkOGYxMzMzZjViYzA3ZmYvMkJGNjA1NDQ1RDIyNDAzRTk0NTVCQTMxMkFEMDM4MkUuemlwIn1dfQ=="
signature: "WWvTjQmMp2JCYR8azpmNgjZHWAM="
utcCreate: "2023-05-26T02:26:00.337Z"
  */
  // var formData = new FormData()
  // formData.append('file', fileList.value[0])
  // formData.append('host', params.host)
  // formData.append('key', params.key)
  // formData.append('ossAccessKeyId', params.ossaccessKeyId)
  // formData.append('policy', params.policy)
  // formData.append('signature', params.signature)
  // uploading.value = true
  // return otaFirmwareUpload(formData)
  //   .then(res => {
  //     // ElMessage.success('上传成功')
  //     // 抛出 uploadSuccess 事件，父组件根据需要监听事件添加对应处理逻辑（一般有再次查询列表处理）
  //     emit('uploadSuccess', { size: fileSize.value, url: params.firmwareUrl })
  //   })
  //   .catch(error => {
  //     console.log(error)
  //     uploading.value = false
  //     ElMessage.warning('请求出错')
  //   })
}

// 对外暴露confirmUpload方法，供父组件控制上传时机
defineExpose({
  confirmUpload,
})

onMounted(() => {})
</script>

<style scoped lang="less">
// 上传弹框样式
.el-upload-list {
  margin-top: 15px;
}
.download-blcok {
  margin-top: 15px;
  text-align: right;
}
</style>
