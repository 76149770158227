<template>
  <el-dialog
    title="上传文件"
    v-model="dialogVisible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    width="600"
    @close="handleBeforeClose"
  >
    <el-upload
      drag
      :limit="1"
      v-model:file-list="fileList"
      accept=".xls, .xlsx"
      :auto-upload="false"
      :before-upload="beforeUpload"
      :on-exceed="onExceed"
      :on-change="onChange"
      :on-success="onSuccess"
      :on-remove="onRemove"
      action=""
    >
      <template #trigger>
        <el-icon class="el-icon--upload"><upload-filled /></el-icon>
        <div class="el-upload__text" style="font-size: 16px">
          将文件拖到此处，或
          <em style="font-size: 16px">点击上传</em>
        </div>
      </template>

      <template #tip v-if="!!props.templeFileUrl">
        <div class="el-upload__tip">请按照文件模板上传正确文件(*.xls,.xlsx)，限制10M</div>
      </template>
    </el-upload>
    <div class="download-blcok" v-if="!!props.templeFileUrl">
      <el-link type="primary" :href="props.templeFileUrl" :icon="Download">下载文件模板</el-link>
    </div>
    <template #footer>
      <div class="dialog-footer">
        <el-button @click="handleCancelUpload">取消</el-button>
        <el-button type="primary" @click="handleConfirmUpload" :loading="uploading"> 确认 </el-button>
      </div>
    </template>
  </el-dialog>
</template>

<script setup lang="ts">
import { ref, onMounted, computed, watch } from 'vue'
import type { UploadProps, UploadUserFile } from 'element-plus'
import { ElMessage } from 'element-plus'
import { Download, UploadFilled } from '@element-plus/icons-vue'

const props = withDefaults(
  defineProps<{
    visible: boolean
    templeFileUrl?: string //文件模板
    uploading?: boolean //确认按钮loading
  }>(),
  {
    visible: false,
    uploading: false,
  },
)

const emit = defineEmits(['confirm', 'cancel', 'update:visible'])

// 使用计算属性接收并更新父组件传入的visible属性，父组件使用 v-model 传入属性，自动监听 update:visible 事件
let dialogVisible = computed({
  get: () => props.visible,
  set: val => {
    emit('update:visible', val)
  },
})

// 弹框关闭，清空 fileList
const handleBeforeClose = () => {
  fileList.value = []
  // dialogVisible.value = false
}

// 上传组件
let fileList = ref([])
const onExceed: UploadProps['onExceed'] = (files, uploadFiles) => {
  console.log('onExceed', files, uploadFiles)
  ElMessage.error(`只能选择 1 个文件，当前共选择了 ${files.length + uploadFiles.length} 个`)
}
const onRemove: UploadProps['onRemove'] = (file, uploadFiles) => {
  console.log('handleRemove', file, uploadFiles, fileList.value)
}
const onChange: UploadProps['onChange'] = (file, uploadFiles) => {
  console.log('handleChange', file, uploadFiles)
  let flag = beforeUpload(file)
  if (flag) {
    fileList.value = [file.raw]
  }
}
// 不会触发？TODO
const onSuccess: UploadProps['onSuccess'] = (response, uploadFiles) => {
  console.log('onSuccess', response, uploadFiles)
}

// :auto-upload="false" 不会触发 :before-upload
const beforeUpload: UploadProps['beforeUpload'] = rawFile => {
  console.log('beforeUpload', rawFile)
  var suffixName = rawFile.name.split('.').pop()
  const isCorrectSuffix = ['xls', 'xlsx'].indexOf(suffixName) != -1
  const isLt10M = rawFile.size / 1024 / 1024 < 10
  if (!isCorrectSuffix) {
    ElMessage.error('上传文件只能是 xls、xlsx格式!')
  }
  if (!isLt10M) {
    ElMessage.error('上传文件大小不能超过 10MB!')
  }
  return isCorrectSuffix && isLt10M
}

// 取消按钮、抛出 cancel 事件，父组件根据需要监听事件添加对应处理逻辑
const handleCancelUpload = () => {
  dialogVisible.value = false
  fileList.value = []
  emit('cancel')
}

// 确认按钮
let uploading = ref(false)
const handleConfirmUpload = () => {
  emit('confirm', fileList.value[0])
}

// 对外暴露refresh方法，清空fileList，供父组件根据需要调用
const refresh = () => {
  fileList.value = []
}
defineExpose({
  refresh,
})

onMounted(() => {})
</script>

<style scoped lang="less">
// 上传弹框样式
.el-upload-list {
  margin-top: 15px;
}
.download-blcok {
  margin-top: 15px;
  text-align: right;
}
</style>
