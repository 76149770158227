import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, normalizeClass as _normalizeClass, renderList as _renderList, createBlock as _createBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = ["src"]
const _hoisted_2 = ["src"]
const _hoisted_3 = ["src"]

import { onBeforeMount, ref, watch, computed } from 'vue'
import { useRouter } from 'vue-router'
import pinia from '@/store'
import { usePermissionStore } from '@/store/permission'
import NavMenuItem from './NavMenuItem.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'NavMenu',
  props: {
    isCollapse: { type: Boolean, default: false },
    mode: { default: 'vertical' }
  },
  setup(__props: any) {

const props = __props

let collapse = computed({
  get: () => (props.mode === 'vertical' ? props.isCollapse : undefined),
  set: val => {},
})

let logoLeft = require('@/assets/img/Gotion1.png')
let logoRight = require('@/assets/img/Gotion2.png')
let logo = require('@/assets/img/Gotion.png')

const permissionStore = usePermissionStore(pinia)
let menuList = permissionStore.routers

const router = useRouter()

watch(
  () => router.currentRoute.value,
  (newValue, oldValue) => {
    // console.log(newValue, oldValue)
    defaultActive.value = newValue.path
  },
)

let defaultActive = ref('/device')
onBeforeMount(() => {
  defaultActive.value = router.currentRoute.value.path
})

return (_ctx: any,_cache: any) => {
  const _component_el_menu = _resolveComponent("el-menu")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass('nav-menu-' + _ctx.mode)
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(['logo', _ctx.isCollapse ? 'collapse' : ''])
    }, [
      (_ctx.mode === 'vertical')
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createElementVNode("img", {
              class: "img-left",
              src: _unref(logoLeft),
              alt: "logo"
            }, null, 8, _hoisted_1),
            (!_ctx.isCollapse)
              ? (_openBlock(), _createElementBlock("img", {
                  key: 0,
                  class: "img-right",
                  src: _unref(logoRight),
                  alt: "logo"
                }, null, 8, _hoisted_2))
              : _createCommentVNode("", true)
          ], 64))
        : (_openBlock(), _createElementBlock("img", {
            key: 1,
            class: "img",
            src: _unref(logo),
            alt: "logo"
          }, null, 8, _hoisted_3))
    ], 2),
    _createVNode(_component_el_menu, {
      class: "custom-menu",
      "default-active": _unref(defaultActive),
      "background-color": "#fff",
      "text-color": "#333",
      "active-text-color": "#eb853b",
      collapse: _unref(collapse),
      mode: _ctx.mode
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(menuList), (route) => {
          return (_openBlock(), _createBlock(NavMenuItem, {
            mode: _ctx.mode,
            collapse: _unref(collapse),
            key: route.path,
            item: route,
            "base-path": route.path
          }, null, 8, ["mode", "collapse", "item", "base-path"]))
        }), 128))
      ]),
      _: 1
    }, 8, ["default-active", "collapse", "mode"])
  ], 2))
}
}

})