import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { ref, computed, watch } from 'vue'
import { ElConfigProvider } from 'element-plus'
import { useLangStore } from './store/lang'
import pinia from '@/store'

import zhCn from 'element-plus/dist/locale/zh-cn.mjs' //最新路径
import En from 'element-plus/dist/locale/en.mjs'

import { useI18n } from 'vue-i18n'

export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

const { locale } = useI18n()

const langStore = useLangStore(pinia)

let currentLang = computed(() => {
  return langStore.lang === 'zhCN' ? zhCn : En
})

// 监听语言切换重新给locale.value赋值，刷新页面 locale.value丢失重置为默认值。放在APP.vue的setup，没有引入change-lang.vue组件的页面也可同步全局语言。
watch(
  () => langStore.lang,
  newValue => {
    locale.value = newValue
  },
  {
    immediate: true,
  },
)

return (_ctx: any,_cache: any) => {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createBlock(_unref(ElConfigProvider), { locale: _unref(currentLang) }, {
    default: _withCtx(() => [
      _createVNode(_component_router_view)
    ]),
    _: 1
  }, 8, ["locale"]))
}
}

})