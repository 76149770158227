import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, renderSlot as _renderSlot, createTextVNode as _createTextVNode, withCtx as _withCtx, createSlots as _createSlots, normalizeStyle as _normalizeStyle, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "common-table-component" }

import { onMounted, reactive, ref, toRef, toRefs, isRef } from 'vue'
import { useTableMaxHeight } from '@/hooks/index'
import { debounce } from 'lodash-es'
// import { TableData, columnItem } from '@/types/CommonTable'

interface TableData {
  tableList: any[]
  multipleSelection?: any[]
}
interface ColumnItem {
  prop: string
  label: string
  width?: number
  minWidth?: number
  showOverflowTooltip?: boolean
  slotName?: string
  headerSlotName?: string // 表格项 表格头 插槽名，slotName+'Header'
}


export default /*@__PURE__*/_defineComponent({
  __name: 'CommonTable',
  props: {
    tableData: { default: () => ({
      tableList: [],
      multipleSelection: [],
    }) },
    columnList: {},
    isShowIndex: { type: Boolean, default: false },
    isShowSelection: { type: Boolean, default: false },
    isShowOption: { type: Boolean, default: false },
    summaryMethod: {},
    loading: { type: Boolean, default: false },
    emptyText: {},
    optionColumnName: {},
    hightlightCurrentRow: { type: Boolean, default: false },
    border: { type: Boolean, default: false },
    headerCellStyle: { type: Boolean, default: true },
    stripe: { type: Boolean, default: true },
    maxHeight: {}
  },
  emits: ['dblClick', 'currentChange', 'selectionChange', 'rowClick'],
  setup(__props: any, { emit: __emit }) {

let props = __props

const emit = __emit

let { tableData, columnList } = toRefs(props)

// 多选
const handleSelectionChange = (val: any[]) => {
  tableData.value.multipleSelection = val
  emit('selectionChange')
}
// 单选
const handleCurrentChange = (currentRow: any, oldCurrentRow: any) => {
  tableData.value.multipleSelection = [currentRow]
  emit('currentChange')
}
// 单击行
const handleRowClick = (row, column, event) => {
  if (props.hightlightCurrentRow) {
    tableData.value.multipleSelection = [row]
  }
  emit('rowClick', row)
}
// 双击行
const handleDblClick = (row: any) => {
  emit('dblClick', row)
}

let maxHeight = ref(props.maxHeight ?? 600)

onMounted(() => {
  if (!props.maxHeight) {
    maxHeight.value = useTableMaxHeight()
    window.addEventListener(
      'resize',
      debounce(() => {
        maxHeight.value = useTableMaxHeight()
      }, 150),
    )
  }
})

return (_ctx: any,_cache: any) => {
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_table = _resolveComponent("el-table")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives((_openBlock(), _createBlock(_component_el_table, {
      data: _unref(tableData).tableList,
      stripe: _unref(props).stripe,
      "max-height": _unref(maxHeight),
      "header-cell-style": _unref(props).headerCellStyle ? { background: '#EBF3FE', color: '#333' } : undefined,
      "show-summary": !!_unref(props).summaryMethod,
      "summary-method": !!_unref(props).summaryMethod ? _unref(props).summaryMethod : undefined,
      "highlight-current-row": _unref(props).hightlightCurrentRow,
      onSelectionChange: handleSelectionChange,
      onCurrentChange: handleCurrentChange,
      onRowDblclick: handleDblClick,
      onRowClick: handleRowClick,
      style: _normalizeStyle({ 'max-height': _unref(maxHeight) + 'px' }),
      "empty-text": _unref(props).emptyText,
      border: _unref(props).border
    }, {
      default: _withCtx(() => [
        (!!_unref(props).isShowIndex)
          ? (_openBlock(), _createBlock(_component_el_table_column, {
              key: 0,
              type: "index",
              width: "55"
            }))
          : _createCommentVNode("", true),
        (!!_unref(props).isShowSelection)
          ? (_openBlock(), _createBlock(_component_el_table_column, {
              key: 1,
              type: "selection",
              width: "55"
            }))
          : _createCommentVNode("", true),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(columnList), (item, index) => {
          return (_openBlock(), _createBlock(_component_el_table_column, {
            key: index,
            prop: item.prop,
            label: item.label,
            "min-width": item.minWidth || 110,
            width: item.width || undefined,
            "show-overflow-tooltip": item.showOverflowTooltip || true
          }, _createSlots({ _: 2 }, [
            (item.headerSlotName)
              ? {
                  name: "header",
                  fn: _withCtx((scope) => [
                    _createTextVNode(_toDisplayString(item.label), 1),
                    (item.headerSlotName)
                      ? _renderSlot(_ctx.$slots, item.headerSlotName, {
                          key: 0,
                          row: scope.row
                        })
                      : _createCommentVNode("", true)
                  ]),
                  key: "0"
                }
              : undefined,
            (item.slotName)
              ? {
                  name: "default",
                  fn: _withCtx((scope) => [
                    (item.slotName)
                      ? _renderSlot(_ctx.$slots, item.slotName, {
                          key: 0,
                          row: scope.row
                        })
                      : _createCommentVNode("", true)
                  ]),
                  key: "1"
                }
              : undefined
          ]), 1032, ["prop", "label", "min-width", "width", "show-overflow-tooltip"]))
        }), 128)),
        (_unref(props).isShowOption)
          ? (_openBlock(), _createBlock(_component_el_table_column, {
              key: 2,
              "min-width": 110,
              label: _unref(props).optionColumnName || '操作',
              fixed: "right"
            }, {
              default: _withCtx((scope) => [
                _renderSlot(_ctx.$slots, "optionColumn", {
                  row: scope.row
                })
              ]),
              _: 3
            }, 8, ["label"]))
          : _createCommentVNode("", true)
      ]),
      _: 3
    }, 8, ["data", "stripe", "max-height", "header-cell-style", "show-summary", "summary-method", "highlight-current-row", "style", "empty-text", "border"])), [
      [_directive_loading, _unref(props).loading]
    ])
  ]))
}
}

})