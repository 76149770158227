<template>
  <div class="main-page">
    <el-container>
      <el-header class="page-header">
        <nav-menu :isCollapse="isCollapse" mode="horizontal"></nav-menu>
        <div class="header-right">
          <!-- <div class="lang-btn-block">
            <change-lang />
          </div> -->
          <el-button text :icon="DataAnalysis" @click="toBigScreen">大屏</el-button>
          <el-dropdown>
            <div class="user-info">
              <el-avatar :size="26" :icon="UserFilled" />
              <span class="name" v-if="username">{{ username }}</span>
            </div>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item @click="handleQuit">退出系统</el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </div>
      </el-header>
      <el-main class="page-content">
        <router-view v-slot="{ Component, route }">
          <!-- <transition
            name="custom-classes-transition"
            :duration="{ enter: 300, leave: 200 }"
            mode="out-in"
            enter-active-class="animated fadeInLeft"
            leave-active-class="animated fadeOutRight"
          > -->
          <keep-alive :include="cachedViews">
            <component :is="Component" :key="route.fullPath" />
          </keep-alive>
          <!-- </transition> -->
        </router-view>
      </el-main>
    </el-container>
  </div>
</template>

<script lang="ts" setup>
import { ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { DataAnalysis, UserFilled } from '@element-plus/icons-vue'
import NavMenu from './NavMenu.vue'
import ChangeLang from '@/components/change-lang.vue'

import { useUserStore } from '@/store/base'
import { usePermissionStore } from '@/store/permission'
import pinia from '@/store'

const isCollapse = ref(false)
const handleChangeCollapse = () => {
  isCollapse.value = !isCollapse.value
}

// 跳转大屏
const router = useRouter()
const toBigScreen = () => {
  router.push('/mobile-charging')
}

// 用户信息
let userStore = useUserStore(pinia)
let permissionStore = usePermissionStore(pinia)
let username = userStore.userInfo.username || ref('')

const cachedViews = permissionStore.cachedViews

const handleQuit = () => {
  userStore.clearUserInfo()
  permissionStore.clearPermission()
  permissionStore.resetRouter(router)
  router.replace('/login')
}
</script>

<style scoped lang="less">
.main-page {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  // background-color: #f8f8fa;
  background-color: #f0f2f5;
  .page-header {
    position: relative;
    display: flex;
    align-items: center;
    color: #333;
    height: 70px;
    background-color: #fff;
    .header-right:extend(.flex-vcenter) {
      font-size: 14px;
      .user-info:extend(.flex-vhcenter) {
        margin-left: 15px;
        cursor: pointer;
        .name {
          margin-left: 5px;
        }
      }
    }
  }
  .page-content {
    height: calc(100vh - 70px);
    font-size: 14px;
    color: #333;
    text-align: left;
    overflow: auto;
  }
}
</style>
